<template>
  <div class="section-universal-carousel">
    <layout-lines fill></layout-lines>
    <div class="container wide">
      <div class="row">
        <div class="col-12 text-left">
          <div class="header">
            <div v-if="$slots.title" class="big-title blue-text">
              <slot name="title"></slot>
            </div>
            <ui-counter
              :current="current + 1"
              :length="length"
              @next="$refs.slider.next()"
              @prev="$refs.slider.prev()"
            ></ui-counter>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$slots.items" class="container wide items-wrapper">
      <div class="row">
        <div class="col-12 no-padd">
          <div class="cards">
            <layout-own-carousel
              ref="slider"
              :no-autoplay="noAutoplay"
              :xs-count="1"
              :count="2"
              :gap="gap"
              @update-current="current = $event"
              @update-length="length = $event"
            >
              <slot name="items"></slot>
            </layout-own-carousel>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$slots.button" class="button-wrapper container">
      <div class="row">
        <div class="col-12 text-left">
          <slot name="button"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    gap: {
      type: String,
      default() {
        return '8em'
      }
    },
    noAutoplay: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      current: 0,
      length: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.section-universal-carousel {
  padding-bottom: 5em;
  position: relative;
}
.header {
  display: flex;
  justify-content: space-between;
}
.items-wrapper {
  margin-top: 4em;
}
.button-wrapper {
  margin-top: 3em;
}
</style>
