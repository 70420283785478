<template>
  <div data-theme="light" class="full-page-section section-principles">
    <layout-lines fill></layout-lines>
    <div class="hidden"><slot name="principles"></slot></div>
    <div class="section-content">
      <div class="container wide">
        <div class="row">
          <div class="col-12 text-left">
            <div class="middle-title blue-text">
              <slot name="title"></slot>
            </div>
          </div>
        </div>
        <!-- <div class="row content">
          <div class="col-6 text-left">
            <div class="tabs">
              <div
                v-for="(tab, index) in items"
                :key="index"
                class="tab"
                :class="[{ active: current === index }]"
                @click="current = index"
              >
                <div class="small-title">{{ tab.name }}</div>
              </div>
            </div>
          </div>
          <div class="col-4 text-left">
            <div class="tabs-contents">
              <div v-for="(item, index) in items" :key="index" class="tab-content">
                <div v-if="index === current" class="tab-desc animated fadeInUp paragraph">
                  {{ item.desc }}
                </div>
                <a v-if="index === current" class="animated fadeInUp" style="animation-delay: .3s" :href="item.path">
                  <ui-button color="dark">Подробнее</ui-button>
                </a>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="l-container content">
        <div class="l-col"></div>
        <div class="l-col l-2">
          <div class="tabs">
            <div
              v-for="(tab, index) in items"
              :key="index"
              class="tab"
              :class="[{ active: current === index }]"
              @click="current = index"
            >
              <div class="small-title">{{ tab.name }}</div>
            </div>
          </div>
        </div>
        <div class="l-col l-2">
          <div class="tabs-contents">
            <div v-for="(item, index) in items" :key="index" class="tab-content">
              <div v-if="index === current" class="tab-desc animated fadeInUp paragraph">
                {{ item.desc }}
              </div>
              <a v-if="index === current" class="animated fadeInUp" style="animation-delay: .3s" :href="item.path">
                <ui-button color="dark">Подробнее</ui-button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      current: 0
    }
  },
  mounted() {
    const items = this.$el.querySelectorAll('.hidden >a')
    for (const item of items) {
      const name = item.querySelector('*:nth-child(1)').textContent
      const desc = item.querySelector('*:nth-child(2)').textContent
      const path = item.pathname
      this.items.push({ name, desc, path })
    }
  }
}
</script>

<style lang="scss" scoped>
.section-principles {
  position: relative;
}
.section-content {
  min-height: 100vh;
  padding: calc(var(--header-height) + 2em) 0;
  // display: flex;
  // align-items: center;
}
.content {
  margin-top: 4em;
}
.tabs {
  border-left: solid 1px rgba(0, 0, 0, 0.3);
}
.tab {
  padding: 1.5em 1.5em;
  cursor: pointer;
  position: relative;
  opacity: 0.2;
  transition: 1s;
  &:before {
    width: 3px;
    height: 0%;
    background: currentColor;
    content: '';
    position: absolute;
    left: -2px;
    top: 0;
    transition: 0.8s;
  }
  &:hover {
    opacity: 1;
  }
  &.active {
    opacity: 1;
    &:before {
      height: 100%;
    }
  }
}
.tab-content {
  a {
    display: block;
    margin-top: 4em;
  }
}
.tabs-contents {
  width: 30em;
  max-width: 100%;
  // padding-right: calc(var(--header-height) * 1.5);
}
@media screen and (max-width: 767px) {
  .section-content {
    min-height: auto;
  }
  .tab {
    padding: 0.8em;
  }
  .tabs-contents {
    width: auto;
    margin-top: 2em;
  }
  .content {
    margin-top: 2em;
  }
}
</style>
