import Vue from 'vue' // Импортируем Vue
import store from '@/store/index' // Импортируем Store
import axios from '@/plugins/axios'
import bus from '@/plugins/bus'
import PortalVue from 'portal-vue'
import VueCookie from 'vue-cookie'

// Подключаем глобально компоненты
import components from '@/plugins/components'

// Tell Vue to use the plugin
Vue.use(VueCookie)
Vue.use(PortalVue)
components(Vue)

Vue.config.productionTip = false // Отключаем предупреждение о режиме разработки

// Подключаем глобально стили
require('@/assets/css/vars.scss')
require('@/assets/css/fonts.scss')
require('@/assets/css/kolbins-grid.css')
require('@/assets/css/style.scss')
require('@/assets/css/animate.css')
// Подключаем Axios
Vue.prototype.$axios = axios

// Создаем event bus
Vue.prototype.$bus = bus

// Вшиваем в this Store
Vue.prototype.$store = store

export default Vue
