<template>
  <div
    class="layout-own-carousel"
    :style="{
      '--size': `calc((var(--default-size) - var(--gap) * ${cCount > 1 ? cCount - 1 : 1}) / ${cCount})`,
      '--gap': `${gap}`,
      '--template': `var(--size)`
    }"
    @mouseenter="mouseenter"
    @mouseleave="mouseleave"
  >
    <div class="slides-wrapper">
      <div
        class="slides"
        :style="{
          transform: `translate3d(calc((var(--size) * ${current} + (var(--gap) * ${current})) * -1),0,0)`
        }"
      >
        <slot></slot>
      </div>
    </div>
    <!--  -->
    <!-- <div v-if="slides.length && dots > 1" class="dots">
      <div
        v-for="(slide, index) in dots"
        :key="index"
        :class="[{ active: index === current }]"
        class="dot"
        @click="goTo(index)"
      ></div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    noAutoplay: {
      type: Boolean,
      default() {
        return false
      }
    },
    count: {
      type: Number,
      default() {
        return 1
      }
    },
    xsCount: {
      type: Number,
      default() {
        return 1
      }
    },
    gap: {
      type: String,
      default() {
        return '0px'
      }
    }
  },
  data() {
    return {
      cCount: this.count,
      current: 0,
      slides: []
    }
  },
  computed: {
    length() {
      return this.slides.length
    },
    max() {
      return this.cCount > 1 ? this.length - this.cCount : this.length - 1
    },
    dots() {
      return this.max + 1
    }
  },
  watch: {
    current: {
      handler() {
        this.slides.forEach((slide, index) => {
          index === this.current ? slide.classList.add('active') : slide.classList.remove('active')
        })
        this.$emit('update-current', this.current)
      }
    }
  },
  mounted() {
    if (window.innerWidth < 768) {
      this.cCount = this.xsCount
    }
    this.init()
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    mouseenter() {
      clearInterval(this.interval)
    },
    mouseleave() {
      this.startTimer()
    },
    goTo(index) {
      clearInterval(this.interval)
      this.current = index
      this.startTimer()
    },
    startTimer() {
      if (this.dots > 1 && !this.noAutoplay) {
        this.interval = setInterval(() => {
          this.next()
        }, 3000)
      }
    },
    init() {
      this.slides = [...this.$el.querySelectorAll('.slides > *')]
      this.slides[0].classList.add('active')
      this.initSwiper()
      this.startTimer()
      this.$emit('update-length', this.dots)
    },
    initSwiper() {
      if (!(this.dots > 1)) {
        return false
      }
      const Hammer = require('hammerjs')
      const stage = this.$el
      const hammertime = new Hammer(stage)
      hammertime.on('swipeleft', ev => {
        this.next()
      })
      hammertime.on('swiperight', ev => {
        this.prev()
      })
    },
    next() {
      clearInterval(this.interval)
      if (this.current !== this.max) {
        this.current++
      } else {
        this.current = 0
      }
      this.startTimer()
    },
    prev() {
      clearInterval(this.interval)
      if (this.current !== 0) {
        this.current--
      } else {
        this.current = this.max
      }
      this.startTimer()
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-own-carousel {
  --default-size: 100%;
}
.slides {
  display: grid;
  grid-auto-columns: var(--template);
  grid-auto-flow: column;
  // align-items: center;
  transition: 0.8s;
  gap: var(--gap);
}
.slides-wrapper {
  position: relative;
  overflow: hidden;
  padding: 4em 0;
  margin: -4em 0;
}
.dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
  height: 10px;
  pointer-events: all;
}
.dot {
  width: 4px;
  height: 4px;
  background: var(--main);
  border-radius: 50%;
  margin: 0 4px;
  transition: 0.4s;
  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
  &.active {
    width: 10px;
    height: 10px;
  }
}
@media screen and (max-width: 767px) {
  .layout-own-carousel {
    --size: calc(100vw - 30px) !important;
  }
}
</style>
