<template>
  <div data-theme="light" class="full-page-section section-about">
    <layout-lines fill></layout-lines>
    <div class="section-content">
      <div class="l-container">
        <div class="l-col"></div>
        <div class="l-col l-2">
          <div class="middle-title blue-text">
            <slot name="title"></slot>
          </div>
          <div class="text rich-text">
            <slot name="text"></slot>
          </div>
        </div>
        <div class="l-col l-2">
          <div class="middle-title blue-text clients-title">
            <slot name="clients-title"></slot>
          </div>
          <div class="clients">
            <slot name="clients"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.section-about {
  position: relative;
}
.section-content {
  min-height: 100vh;
  padding: calc(var(--header-height) + 4em) 0;
}
.text {
  margin-top: 4em;
  width: 33em;
  max-width: 100%;
}
.clients-title {
  opacity: 0.2;
}
.clients {
  margin-top: 4em;
  display: grid;
  grid-template-columns: repeat(3, 7.5em);
  justify-content: space-between;
  padding-right: calc(var(--header-height) + 2em);
  align-items: center;
  grid-gap: 4em 0;
  img {
    filter: grayscale(100%);
    transition: 1s;
    &:hover {
      filter: none;
    }
    // max-height: 2em;
  }
}
@media screen and (max-width: 767px) {
  .section-content {
    min-height: 0;
    padding: var(--header-height) 0;
  }
  .text {
    width: auto;
  }
  .clients-title {
    margin-top: 2em;
  }
  .clients {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3em;
  }
}
</style>
