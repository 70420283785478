<template>
  <div class="layout-sidebar" :class="[theme]">
    <div class="nav">
      <div v-for="(line, index) in length" :key="index" :class="[{ active: index === current }]" class="line"></div>
    </div>
    <icon-arrow-bottom @click="scrollDown"></icon-arrow-bottom>
  </div>
</template>

<script>
export default {
  computed: {
    length() {
      return this.$store.state.nav.length
    },
    current() {
      return this.$store.state.nav.current
    },
    theme() {
      return this.$store.state.nav.theme
    }
  },
  methods: {
    scrollDown() {
      if (window.fullpage_api) {
        window.fullpage_api.moveSectionDown()
      } else {
        const section = document.querySelector('.layout-page-wrapper > *:nth-child(2)')
        const offset = window.pageYOffset + section.getBoundingClientRect().top - 100
        window.scrollTo({ top: offset, behavior: 'smooth' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-sidebar {
  position: fixed;
  width: var(--sidebar-width);
  border-right: solid 1px var(--line);
  height: 100vh;
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: calc(var(--sidebar-width) / 5);
  z-index: 100;
  transition: 1s;
  top: 0;
}
.nav {
  margin: auto 0;
}
.line {
  width: 16px;
  height: 2px;
  background: currentColor;
  border-radius: 10px;
  margin: 1.5em 0;
  transition: 1s;
  &.active {
    width: 60px !important;
  }
  &:nth-child(2n + 1) {
    width: 10px;
  }
}
@keyframes arrow-bottom {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(0, 2em);
    opacity: 0.5;
  }
}
.icon-arrow-bottom {
  animation-name: arrow-bottom;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}
@media screen and (max-width: 767px) {
  .layout-sidebar {
    display: none;
  }
}
</style>

<style lang="scss">
.layout-sidebar {
  &.light {
    color: var(--color);
    .icon-arrow-bottom {
      path {
        fill: var(--color);
      }
    }
  }
}
</style>
