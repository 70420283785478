<template>
  <div class="ui-index-text" :class="[{ large }]">
    <div class="index"><slot name="index"></slot></div>
    <div class="small-title blue-text">
      <slot name="title"></slot>
    </div>
    <div class="small-paragraph">
      <slot name="subtitle"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    large: {
      type: Boolean,
      default() {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-index-text {
  position: relative;
  margin-bottom: 5em;
  &.large {
    .small-title {
      font-size: 3.5em;
    }
    .index {
      font-size: 16em;
      top: -0.4em;
    }
  }
}
.small-paragraph {
  margin-top: 1em;
}
.index {
  position: absolute;
  top: -0.5em;
  font-size: 10em;
  font-weight: 900;
  color: var(--dark);
  opacity: 0.05;
  pointer-events: none;
}
</style>
