<template>
  <div class="section-logos">
    <layout-lines fill></layout-lines>
    <div class="section-bg"></div>
    <div class="container wide">
      <div class="row">
        <div class="col-12 text-left">
          <div class="middle-title blue-text">
            <slot name="title"></slot>
          </div>
          <div class="logos">
            <slot name="logos"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.section-logos {
  padding: 3em 0 7em 0;
  position: relative;
}
.section-bg {
  height: calc(100% - 4em);
}
.logos {
  margin-top: 3em;
  display: flex;
  align-items: center;
  & > * {
    mix-blend-mode: darken;
    &:not(:last-child) {
      margin-right: 4em;
    }
  }
}
@media screen and (max-width: 767px) {
  .logos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3em 2em;
    & > * {
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
}
</style>
