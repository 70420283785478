<template>
  <div class="v-lines layout-lines" :class="[{ fill }]">
    <div v-for="line in 6" :key="line" class="v-line"></div>
  </div>
</template>

<script>
export default {
  props: {
    fill: {
      type: Boolean,
      default() {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-lines {
  height: calc(100% - var(--header-height));
  position: absolute;
  left: var(--sidebar-width);
  bottom: 0;
  display: flex;
  pointer-events: none;
  &.fill {
    height: 100%;
  }
}
.v-line {
  height: 100%;
  background: var(--line);
  width: 1px;
  margin-left: var(--sidebar-width);
}
@media screen and (max-width: 767px) {
  .v-lines {
    left: 0;
  }
}
</style>
