import Vue from '@/index.js'

import page from '@/mixins/page'

export default new Vue({
  el: '#app',
  components: {},
  mixins: [page],
  mounted() {
    const hasFullPageContainer = document.querySelector('.fullpage')
    hasFullPageContainer && window.innerWidth > 767 ? this.initFullPage() : this.initMagicPage()
  },
  methods: {
    initFullPage() {
      // this.$store.state.sections = this.page.sections
      setTimeout(() => {
        const sections = document.querySelectorAll('.fullpage .full-page-section')
        const firstSection = sections[0]
        this.$store.state.nav.length = sections.length
        this.$store.state.nav.section = firstSection
        this.$store.state.nav.theme = firstSection.dataset.theme
        // require('fullpage.js/vendors/scrolloverflow')
        // const Fullpage = require('fullpage.js')
        const Fullpage = window.fullpage
        const fp = new Fullpage('.fullpage', {
          licenseKey: '82496388-B6D14808-A3208C6F-8FA1ACD8',
          // options here
          sectionSelector: '.full-page-section',
          // anchors: this.page.sections.map((s, i) => {
          //   return `section-${i}`
          // }),
          autoScrolling: true,
          scrollingSpeed: 1500,
          scrollOverflow: true,
          onLeave: (origin, destination, direction) => {
            this.$store.state.nav.current = destination.index
            this.$store.state.nav.section = destination.item
            this.$store.state.nav.theme = destination.item.dataset.theme
            // console.log(destination)
          }
          // scrollHorizontally: true
        })
        console.log(fp)
      }, 0)
    },
    initMagicPage() {
      const sections = [...document.querySelectorAll('.layout-page-wrapper > *')]
      const firstSection = sections[0]
      const length = sections.length
      this.$store.state.nav.theme = 'light'
      this.$store.state.nav.length = length
      this.$store.state.nav.section = firstSection
      //
      const positions = sections.map(s => s.offsetTop)
      window.addEventListener('scroll', () => {
        const scrollY = window.scrollY
        const i = positions.findIndex(pos => scrollY < pos) - 1
        const index = i < 0 ? 0 : i
        this.$store.state.nav.current !== index ? (this.$store.state.nav.current = index) : ''
      })
    }
  }
})
