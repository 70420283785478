<template>
  <div class="ui-search-button">
    <icon-zoom></icon-zoom>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.ui-search-button {
  width: var(--header-height);
  height: var(--header-height);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 1s;
}
@media screen and (max-width: 767px) {
  svg {
    width: 1.25em;
    height: auto;
  }
  .ui-search-button {
    border-right: solid 1px rgba(255, 255, 255, 0.3);
  }
}
</style>
