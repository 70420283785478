<template>
  <div data-theme="dark" class="full-page-section section-screen">
    <layout-lines></layout-lines>
    <div class="section-content">
      <div class="image abs">
        <slot name="image"></slot>
        <div class="overlay abs"></div>
      </div>
      <div class="symbol-wrapper abs">
        <icon-symbol></icon-symbol>
      </div>
      <div class="l-container">
        <div class="l-col"></div>
        <div class="l-col l-2">
          <div class="great-title">
            <slot name="title"></slot>
          </div>
          <div class="subtitle">
            <slot name="subtitle"></slot>
          </div>
          <div class="button-wrapper">
            <slot name="button"></slot>
          </div>
        </div>
        <div class="l-col l-2">
          <div class="desc subtitle">
            <slot name="desc"></slot>
          </div>
        </div>
      </div>
      <div class="bottom-data">
        <div class="carousel-wrapper">
          <ui-counter no-arrows :length="length" :current="current + 1"></ui-counter>
          <div
            class="carousel"
            :style="{
              transform: `translate3d(0,calc(100% * ${current} * -1),0)`
            }"
          >
            <slot name="carousel"></slot>
          </div>
          <div class="arrows-wrapper">
            <div :class="[{ inactive: !current }]" class="arrow" @click="prev">
              <svg width="31" height="8" viewBox="0 0 31 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.646444 4.35355C0.451183 4.15829 0.451183 3.84171 0.646444 3.64645L3.82842 0.464466C4.02369 0.269204 4.34027 0.269204 4.53553 0.464466C4.73079 0.659728 4.73079 0.976311 4.53553 1.17157L1.70711 4L4.53553 6.82843C4.73079 7.02369 4.73079 7.34027 4.53553 7.53553C4.34027 7.7308 4.02369 7.7308 3.82842 7.53553L0.646444 4.35355ZM31 4.5H0.999998V3.5H31V4.5Z"
                  fill="white"
                />
              </svg>
            </div>
            <div :class="[{ inactive: length - 1 === current }]" class="arrow" @click="next">
              <svg width="31" height="8" viewBox="0 0 31 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M30.3536 4.35355C30.5488 4.15829 30.5488 3.84171 30.3536 3.64645L27.1716 0.464466C26.9763 0.269204 26.6597 0.269204 26.4645 0.464466C26.2692 0.659728 26.2692 0.976311 26.4645 1.17157L29.2929 4L26.4645 6.82843C26.2692 7.02369 26.2692 7.34027 26.4645 7.53553C26.6597 7.7308 26.9763 7.7308 27.1716 7.53553L30.3536 4.35355ZM0 4.5H30V3.5H0V4.5Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="video-wrapper">
          <div
            class="images abs"
            :style="{
              transform: `translate3d(calc(100% * ${current} * -1),0,0)`
            }"
          >
            <slot name="images"></slot>
          </div>
        </div>
      </div>
    </div>
    <portal v-if="videoOpened" to="z-popup">
      <layout-video-player @close="videoOpened = false">
        <slot name="video"></slot>
      </layout-video-player>
    </portal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoOpened: false,
      current: 0,
      length: 0
    }
  },
  mounted() {
    const length = this.$el.querySelectorAll('.carousel >*').length
    this.length = length
    //
    this.initTimer()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    prev() {
      clearInterval(this.timer)
      if (this.current !== 0) {
        this.current--
      } else {
        this.current = this.length - 1
      }
      this.initTimer()
    },
    next() {
      clearInterval(this.timer)
      if (this.current !== this.length - 1) {
        this.current++
      } else {
        this.current = 0
      }
      this.initTimer()
    },
    initTimer() {
      this.timer = setInterval(() => {
        this.next()
      }, 3000)
    }
  }
}
</script>

<style lang="scss" scoped>
.section-screen {
  position: relative;
  overflow: hidden;
  color: #fff;
}
.layout-lines {
  z-index: 2;
}
.section-content {
  display: flex;
  align-items: center;
  min-height: 100vh;
}
.overlay {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    radial-gradient(26% 26% at 26.81% 91.44%, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%),
    radial-gradient(31.5% 99.56% at 30.31% 47.44%, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%),
    radial-gradient(27.87% 88.1% at 86.69% 41.44%, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
}
.image {
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.symbol-wrapper {
  mix-blend-mode: hard-light;
  svg {
    position: absolute;
    top: -244px;
    left: 400px;
  }
}
.great-title {
  margin-bottom: 0.5em;
}
.subtitle {
  font-size: 1.375em;
}
.desc {
  line-height: 180%;
  width: 20em;
  max-width: 100%;
  margin-top: 0.5em;
}
.button-wrapper {
  margin-top: 15vh;
}
.bottom-data {
  position: absolute;
  right: -1px;
  bottom: 0;
  display: flex;
  z-index: 2;
  & > * {
    width: var(--sidebar-width);
  }
}
.play-button {
  width: 5em;
  height: 5em;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    width: 100%;
    height: 0%;
    left: 0;
    bottom: 0;
    background: var(--main);
    position: absolute;
    transition: 0.7s;
  }
  &:hover {
    &:before {
      height: 100%;
    }
    svg {
      path {
        fill: #fff;
      }
    }
  }
  svg {
    position: relative;
    path {
      transition: 0.5s;
    }
  }
}
.carousel-wrapper {
  background: #000;
  color: #fff;
  position: relative;
  overflow: hidden;
  .ui-counter {
    position: absolute;
    left: 1.5em;
  }
}
.arrows-wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  pointer-events: none;
  bottom: 0;
  padding: 0.7em 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > * {
    pointer-events: all;
    padding: 1em 0.7em;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      transform: scale(1.02);
    }
    &.inactive {
      opacity: 0.3;
    }
  }
}
.video-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 12em;
  background: #000;
  overflow: hidden;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
.images {
  display: grid;
  grid-auto-columns: 100%;
  grid-auto-flow: column;
  transition: 1s;
  & > * {
    position: relative;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.carousel {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  grid-auto-rows: 100%;
  grid-auto-flow: row;
  transition: 0.5s;
  & > * {
    width: 100%;
    padding-top: 4em;
    padding-left: 1.8em;
    padding-right: 4em;
    font-size: 1.125em;
  }
}
@media screen and (max-width: 767px) {
  .section-content {
    flex-direction: column;
    min-height: 100vh;
    padding-top: calc(var(--header-height) + 4em);
  }
  .bottom-data {
    position: relative;
    right: auto;
    bottom: auto;
    width: 100%;
    margin-top: auto;
    & > * {
      width: 50%;
    }
  }
  .button-wrapper {
    margin-top: 3em;
  }
  .carousel-wrapper {
    .ui-counter {
      flex-direction: row;
      font-size: 0.75em;
    }
  }
  .desc {
    width: auto;
    margin-bottom: 3em;
  }
  .carousel {
    & > * {
      padding-top: 3em;
      padding-left: 1em;
      padding-right: 1em;
    }
  }
  .overlay {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      radial-gradient(26% 26% at 26.81% 91.44%, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%),
      radial-gradient(31.5% 99.56% at 30.31% 47.44%, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%),
      radial-gradient(27.87% 88.1% at 86.69% 41.44%, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
  }
}
</style>
