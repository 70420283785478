<template>
  <div class="layout-video-player abs">
    <div class="overlay abs" @click="$emit('close')"></div>
    <div class="video-player">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.layout-video-player {
  position: fixed;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
}
.overlay {
  background: rgba(0, 0, 0, 0.7);
}
.video-player {
  width: 70%;
  padding-bottom: 40%;
  position: relative;
  background: #000;
}
</style>

<style lang="scss">
.video-player {
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
</style>
