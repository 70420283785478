import { render, staticRenderFns } from "./ui-button.vue?vue&type=template&id=56adb0d0&scoped=true&"
import script from "./ui-button.vue?vue&type=script&lang=js&"
export * from "./ui-button.vue?vue&type=script&lang=js&"
import style0 from "./ui-button.vue?vue&type=style&index=0&id=56adb0d0&lang=scss&scoped=true&"
import style1 from "./ui-button.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56adb0d0",
  null
  
)

export default component.exports