<template>
  <div :class="[empty ? 'empty' : '']" class="ui-input">
    <label class="caption" v-text="label + `${required ? '*' : ''}`" />
    <textarea
      v-if="type === 'textarea'"
      :value="tempValue"
      :required="required"
      :placeholder="placeholder"
      :name="name"
      rows="3"
      @input="input"
    />
    <select
      v-else-if="type === 'select'"
      :value="tempValue"
      :required="required"
      :placeholder="placeholder"
      :name="name"
    >
      <slot></slot>
    </select>
    <input
      v-else
      :placeholder="placeholder"
      :value="tempValue"
      :required="required"
      :name="name"
      :type="type"
      @input="input"
      @change="change"
    />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default() {
        return 'text'
      }
    },
    name: {
      type: String,
      default() {
        return ''
      }
    },
    example: {
      type: String,
      default() {
        return ''
      }
    },
    value: {
      type: [String, Number],
      default() {
        return ''
      }
    },
    required: {
      type: Boolean,
      default() {
        return false
      }
    },
    lazy: {
      type: Boolean,
      default() {
        return false
      }
    },
    slider: {
      type: Boolean,
      default() {
        return false
      }
    },
    label: {
      type: String,
      default() {
        return ''
      }
    },
    min: {
      type: Number,
      default() {
        return 0
      }
    },
    max: {
      type: Number,
      default() {
        return 99999
      }
    },
    step: {
      type: Number,
      default() {
        return 1
      }
    },
    postfix: {
      type: String,
      default() {
        return ''
      }
    },
    placeholder: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      tempValue: this.value
    }
  },
  computed: {
    empty() {
      return this.tempValue === '' && this.value === ''
    }
  },
  mounted() {
    if (this.type === 'phone') {
      const Inputmask = require('inputmask').default
      const input = this.$el.querySelector('input[type=phone]')
      const im = new Inputmask({
        mask: '+7 (999) 999-99-99',
        showMaskOnHover: false
      })
      im.mask(input)
    }
  },
  methods: {
    input($event) {
      if (this.lazy) {
        return false
      }
      this.updateValue($event)
    },
    change($event) {
      if (!this.lazy) {
        return false
      }
      this.updateValue($event)
    },
    updateValue($event) {
      let value = $event ? $event.target.value : this.value
      this.tempValue = value
      if (this.type === 'number') {
        value = Number(value)
        this.tempValue = value
      }
      this.$emit('input', value)
      // this.updateSlider()
    },
    onInput({ number, isValid, country }) {
      this.tempValue = number
      this.$emit('input', number)
      const field = this.$el.querySelector('.hidden-phone')
      if (!isValid) {
        field.setCustomValidity('Invalid phone')
      } else {
        field.setCustomValidity('')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-input {
  position: relative;
  text-align: left;
  margin-bottom: 3em;
  background: inherit;
}
input,
textarea,
select {
  font-size: 1.875em;
  font-family: inherit;
  // border: solid 2px rgba(#f0f0f7, 1);
  border: none;
  border-bottom: solid 3px var(--color);
  padding: 0.4em 0;
  border-radius: 0px;
  width: 100%;
  color: var(--dark);
  transition: 0.3s;
  height: 100%;
  resize: vertical;
  background: inherit;
  transition: 0.5s;
  &:focus {
    border-color: var(--dark);
  }
  &::-webkit-input-placeholder {
    color: #d3d5dd;
  }
  &::-moz-placeholder {
    color: #d3d5dd;
  }
  &:-moz-placeholder {
    color: #d3d5dd;
  }
  &:-ms-input-placeholder {
    color: #d3d5dd;
  }
}
.example {
  font-size: 0.75em;
  padding-left: 1.5em;
  padding-top: 0.2em;
}
.postfix {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 1em;
  font-weight: 500;
  pointer-events: none;
}
</style>
