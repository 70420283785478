import { render, staticRenderFns } from "./layout-video-player.vue?vue&type=template&id=33b10066&scoped=true&"
import script from "./layout-video-player.vue?vue&type=script&lang=js&"
export * from "./layout-video-player.vue?vue&type=script&lang=js&"
import style0 from "./layout-video-player.vue?vue&type=style&index=0&id=33b10066&lang=scss&scoped=true&"
import style1 from "./layout-video-player.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33b10066",
  null
  
)

export default component.exports