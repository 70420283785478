<template>
  <div class="section-tariffs">
    <layout-lines fill></layout-lines>
    <div class="container wide">
      <div class="row">
        <div class="col-12 text-left">
          <div class="big-title blue-text">
            <slot name="title"></slot>
          </div>
        </div>
      </div>
      <div class="row tariffs-wrapper flex-xs-wrap">
        <div class="col-9 col-xs-12 text-left">
          <div class="tariffs">
            <slot name="tariffs"></slot>
          </div>
        </div>
        <div class="col-3 col-xs-12 text-left">
          <div class="features">
            <slot name="features"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.section-tariffs {
  padding-bottom: 4em;
  position: relative;
}
.tariffs {
  display: grid;
  grid-template-columns: 1fr 1.1fr 1fr;
  align-items: flex-start;
  padding-right: 3em;
  & > *:nth-child(2) {
    transform: scale(1.02);
    z-index: 2;
    padding: 3em 2em;
    border: solid 6px var(--dark);
    &:after {
      content: '';
      width: 4.6em;
      height: 4.6em;
      position: absolute;
      background: url('/src/assets/icons/hit.svg');
      background-size: contain;
      background-position: right top;
      background-repeat: no-repeat;
      right: -9px;
      top: -9px;
    }
  }
}
.tariffs-wrapper {
  margin-top: 3em;
}
.features {
  & > * {
    &:not(:last-child) {
      margin-bottom: 3em;
    }
  }
}
@media screen and (max-width: 767px) {
  .tariffs {
    display: block;
    padding-right: 0;
    & > * {
      margin-bottom: 1em;
    }
  }
}
</style>
