<template>
  <div class="layout-personal-card">
    <div class="image">
      <slot name="image"></slot>
      <div class="overlay abs"></div>
    </div>
    <div class="content">
      <div class="small-title name"><slot name="name"></slot></div>
      <div class="caption subname"><slot name="subname"></slot></div>
      <div class="desc caption">
        <slot name="desc"></slot>
      </div>
      <div class="button-wrapper">
        <slot name="button"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.layout-personal-card {
  // height: 65vh;
  position: relative;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
  }
  // width: 32em;
}
.overlay {
  background: linear-gradient(270deg, rgba(55, 51, 48, 0.93) 37.72%, rgba(69, 55, 43, 0) 84.33%);
}
.content {
  position: relative;
  width: 60%;
  max-width: 100%;
  text-align: left;
  padding: 3em 2em;
  display: flex;
  flex-direction: column;
}
.name {
  margin-bottom: 0.4em;
}
.subname {
  opacity: 0.7;
}
.desc {
  line-height: 180%;
  margin-top: 2em;
  margin-bottom: 2em;
}
.button-wrapper {
  margin-top: auto;
}
@media screen and (max-width: 767px) {
  .image {
    height: 25em;
    position: relative;
  }
  .overlay {
    background: linear-gradient(360deg, rgba(55, 51, 48, 1) 0%, rgba(69, 55, 43, 0) 84.33%);
  }
  .layout-personal-card {
    height: auto;
    flex-direction: column;
  }
  .content {
    width: auto;
    position: relative;
    background: rgba(55, 51, 48, 1);
  }
  .ui-button {
    margin-top: 2em;
  }
}
</style>
