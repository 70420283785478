<template>
  <svg class="icon-line-left" width="31" height="8" viewBox="0 0 31 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.646444 4.35355C0.451183 4.15829 0.451183 3.84171 0.646444 3.64645L3.82842 0.464466C4.02369 0.269204 4.34027 0.269204 4.53553 0.464466C4.73079 0.659728 4.73079 0.976311 4.53553 1.17157L1.70711 4L4.53553 6.82843C4.73079 7.02369 4.73079 7.34027 4.53553 7.53553C4.34027 7.7308 4.02369 7.7308 3.82842 7.53553L0.646444 4.35355ZM31 4.5H0.999998V3.5H31V4.5Z"
      fill="var(--color)"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.foo {
}
</style>
