import pageLayoutDefault from '@/components/page/page-layout-default'

// Sections
import sectionScreen from '@/components/sections/section-screen'
import sectionPersonal from '@/components/sections/section-personal'
import sectionPrinciples from '@/components/sections/section-principles'
import sectionProjects from '@/components/sections/section-projects'
import sectionAbout from '@/components/sections/section-about'
import sectionRichText from '@/components/sections/section-rich-text'
import sectionDigitsCarousel from '@/components/sections/section-digits-carousel'
import sectionLogos from '@/components/sections/section-logos'
import sectionUniversalCarousel from '@/components/sections/section-universal-carousel'
import sectionServices from '@/components/sections/section-services'
import sectionFaq from '@/components/sections/section-faq'
import sectionContacts from '@/components/sections/section-contacts'
import sectionTariffs from '@/components/sections/section-tariffs'
import sectionFeaturesColumns from '@/components/sections/section-features-columns'
import sectionSteps from '@/components/sections/section-steps'

// UI
import uiButton from '@/components/ui/ui-button'
import uiCounter from '@/components/ui/ui-counter'
import uiIndexText from '@/components/ui/ui-index-text'
import uiFaqCard from '@/components/ui/ui-faq-card'
import uiInput from '@/components/ui/ui-input'

// Icons
import iconPhone from '@/components/icons/icon-phone'
import iconZoom from '@/components/icons/icon-zoom'
import iconClose from '@/components/icons/icon-close'
import iconArrowBottom from '@/components/icons/icon-arrow-bottom'
import iconArrowRight from '@/components/icons/icon-arrow-right'
import iconSymbol from '@/components/icons/icon-symbol'
import iconLineLeft from '@/components/icons/icon-line-left'
import iconLineRight from '@/components/icons/icon-line-right'

// Layout
import layoutVideoPlayer from '@/components/layout/layout-video-player'
import layoutLines from '@/components/layout/layout-lines'
import layoutPersonalCard from '@/components/layout/layout-personal-card'
import layoutOwnCarousel from '@/components/layout/layout-own-carousel'
import layoutProjectCard from '@/components/layout/layout-project-card'
import layoutTariff from '@/components/layout/layout-tariff'
import layoutFooter from '@/components/layout/layout-footer'
import layoutModalPopup from '@/components/layout/layout-modal-popup'

const components = Vue => {
  Vue.component('page-layout-default', pageLayoutDefault)

  // Sections
  Vue.component('section-screen', sectionScreen)
  Vue.component('section-personal', sectionPersonal)
  Vue.component('section-principles', sectionPrinciples)
  Vue.component('section-projects', sectionProjects)
  Vue.component('section-about', sectionAbout)
  Vue.component('section-rich-text', sectionRichText)
  Vue.component('section-digits-carousel', sectionDigitsCarousel)
  Vue.component('section-logos', sectionLogos)
  Vue.component('section-universal-carousel', sectionUniversalCarousel)
  Vue.component('section-services', sectionServices)
  Vue.component('section-faq', sectionFaq)
  Vue.component('section-contacts', sectionContacts)
  Vue.component('section-tariffs', sectionTariffs)
  Vue.component('section-features-columns', sectionFeaturesColumns)
  Vue.component('section-steps', sectionSteps)

  // UI
  Vue.component('ui-button', uiButton)
  Vue.component('ui-counter', uiCounter)
  Vue.component('ui-index-text', uiIndexText)
  Vue.component('ui-faq-card', uiFaqCard)
  Vue.component('ui-input', uiInput)

  // Icons
  Vue.component('icon-phone', iconPhone)
  Vue.component('icon-zoom', iconZoom)
  Vue.component('icon-close', iconClose)
  Vue.component('icon-arrow-bottom', iconArrowBottom)
  Vue.component('icon-arrow-right', iconArrowRight)
  Vue.component('icon-symbol', iconSymbol)
  Vue.component('icon-line-left', iconLineLeft)
  Vue.component('icon-line-right', iconLineRight)

  // Layout
  Vue.component('layout-personal-card', layoutPersonalCard)
  Vue.component('layoutOwnCarousel', layoutOwnCarousel)
  Vue.component('layout-project-card', layoutProjectCard)
  Vue.component('layout-tariff', layoutTariff)
  Vue.component('layout-footer', layoutFooter)
  Vue.component('layout-lines', layoutLines)
  Vue.component('layout-video-player', layoutVideoPlayer)
  Vue.component('layout-modal-popup', layoutModalPopup)
}
export default components
