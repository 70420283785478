<template>
  <footer data-theme="light" class="layout-footer full-page-section fp-auto-height">
    <layout-lines fill></layout-lines>
    <div class="container wide">
      <div class="row flex-xs-wrap">
        <!-- <div class="col-3 col-xs-6 text-left">
          <div class="column">
            <div class="column-header">проекты</div>
            <div class="links">
              <slot name="projects"></slot>
            </div>
          </div>
        </div> -->
        <div class="col-4 col-xs-6 text-left">
          <div class="column">
            <div class="column-header">услуги</div>
            <div class="links">
              <slot name="services"></slot>
            </div>
          </div>
        </div>
        <div class="col-4 col-xs-6 text-left">
          <div class="column">
            <div class="column-header">компания</div>
            <div class="links">
              <slot name="menu"></slot>
            </div>
          </div>
        </div>
        <div class="col-4 col-xs-6 text-left">
          <div class="contacts">
            <slot name="contacts"></slot>
          </div>
          <div class="socials">
            <slot name="socials"></slot>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="bottom row flex-xs-wrap">
        <div class="col-9 col-xs-6 text-left">
          <div class="copyrights">
            <slot name="copyrights"></slot>
          </div>
        </div>
        <div class="col-3 col-xs-6 text-left">
          <div class="madeby">
            <slot name="madeby"></slot>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.layout-footer {
  padding-top: 2em;
  position: relative;
}
.column-header {
  font-size: 1.125em;
  font-weight: 800;
  margin-bottom: 1em;
}
.links {
  & > * {
    display: block;
    text-decoration: underline;
    font-size: 0.875em;
    margin-bottom: 1em;
    color: var(--subcolor);
  }
}
.contacts {
  & > * {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5em;
    &:hover {
      & > *:nth-child(1) {
        color: var(--dark);
      }
    }
    & > * {
      &:nth-child(1) {
        font-size: 1.375em;
        font-weight: 800;
        margin-bottom: 0.2em;
        transition: 0.5s;
      }
      &:nth-child(2) {
        font-size: 0.75em;
        color: var(--subcolor);
      }
    }
  }
}
.socials {
  & > * {
    margin-right: 0.5em;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      opacity: 0.4;
    }
  }
}
.bottom {
  margin-top: 2em;
}
.copyrights,
.madeby {
  font-size: 0.75em;
  color: var(--subcolor);
}
</style>
