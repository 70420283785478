<template>
  <div class="section-features-columns">
    <layout-lines fill></layout-lines>
    <div class="section-bg"></div>
    <div class="container wide">
      <div class="row">
        <div class="col-12 text-left">
          <div class="middle-title blue-text">
            <slot name="title"></slot>
          </div>
          <div class="paragraph">
            <slot name="subtitle"></slot>
          </div>
        </div>
      </div>
      <div class="row features-wrapper">
        <div class="col-12 text-left">
          <div class="features">
            <slot name="features"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.section-features-columns {
  padding: 3em 0 7em 0;
  position: relative;
}
.section-bg {
  height: calc(100% - 4em);
}
.paragraph {
  margin-top: 1em;
  color: var(--subcolor);
}
.features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 7em;
}
.features-wrapper {
  margin-top: 2em;
}
@media screen and (max-width: 767px) {
  .features {
    grid-template-columns: repeat(1, 1fr);
    gap: 3em;
  }
}
</style>
