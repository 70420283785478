<template>
  <button class="ui-button" :class="[color]">
    <span>
      <slot></slot>
    </span>
    <div class="icon-wrapper">
      <icon-arrow-right></icon-arrow-right>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default() {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-button {
  background: none;
  color: inherit;
  font-family: inherit;
  font-size: 1.25em;
  font-weight: 600;
  display: flex;
  border: solid 2px;
  align-items: center;
  --margin: 1em;
  padding-left: var(--margin);
  cursor: pointer;
  &:hover {
    .icon-wrapper {
      &::before {
        height: 100%;
      }
    }
  }
}
.icon-wrapper {
  width: 3em;
  background: currentColor;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: var(--margin);
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 0%;
    left: 0;
    bottom: 0;
    background: var(--main);
    position: absolute;
    transition: 0.7s;
  }
  svg {
    position: relative;
  }
}
</style>

<style lang="scss">
.ui-button {
  &:hover {
    .icon-wrapper {
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
  &.dark {
    color: var(--dark);
    .icon-wrapper {
      &::before {
        background: #fff;
      }
      svg {
        path {
          fill: #fff;
        }
      }
    }
    &:hover {
      .icon-wrapper {
        svg {
          path {
            fill: var(--dark);
          }
        }
      }
    }
  }
}
</style>
