import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    nav: {
      length: 0,
      current: 0,
      section: null,
      theme: ''
    }
  },
  mutations: {},
  actions: {},
  modules: {}
})
