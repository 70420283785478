var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-finder"},[_c('icon-zoom',{staticClass:"search"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],attrs:{"autofocus":"","type":"text","placeholder":"Начните искать..."},domProps:{"value":(_vm.query)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.query=$event.target.value},_vm.fetch]}}),_c('icon-close',{staticClass:"close",nativeOn:{"click":function($event){return _vm.$emit('close')}}}),_c('transition',{attrs:{"enter-active-class":"animated fadeInDown"}},[(_vm.resultsOpened)?_c('div',{staticClass:"results-wrapper"},[_c('div',{staticClass:"results-info"},[(_vm.query)?_c('span',[_vm._v("Найдено "+_vm._s(_vm.results.length)+" результатов по запросу: "+_vm._s(_vm.query))]):_c('span',[_vm._v("Введите текст для поиска")])]),_c('div',{staticClass:"results-scroll-area"},[_c('vue-scroll',{attrs:{"ops":{
            rail: {
              background: '#fff'
            },
            bar: {
              background: '#fff'
            }
          }}},[_c('div',{staticClass:"results"},_vm._l((_vm.results),function(result,index){return _c('a',{key:index,staticClass:"result",attrs:{"href":result.link}},[_c('div',{staticClass:"result-title"},[_vm._v(_vm._s(result.title))]),_c('div',{staticClass:"result-type"},[_vm._v(_vm._s(result.type))])])}),0)])],1)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }