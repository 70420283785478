<template>
  <svg
    class="icon-symbol"
    width="1801"
    height="1800"
    viewBox="0 0 1801 1800"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1578.55 597.535L1039.93 1530.46C977.741 1638.17 822.258 1638.17 760.069 1530.46L221.448 597.535C159.259 489.822 236.997 355.171 361.383 355.171H1438.62C1563.01 355.171 1640.75 489.822 1578.55 597.535ZM1592.16 185.453C1163.85 -61.8177 636.153 -61.8177 207.852 185.453C79.2298 259.715 0 396.95 0 545.459C0 1040.02 263.835 1497.02 692.151 1744.31C820.774 1818.56 979.225 1818.56 1107.85 1744.31C1536.16 1497.02 1800.01 1040.02 1800.01 545.459C1800.01 396.95 1720.77 259.715 1592.16 185.453Z"
      fill="#3A9AD5"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.foo {
}
</style>
