<template>
  <a class="ui-logo">
    <svg viewBox="0 0 192 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        class="symbol"
        d="M189.987 5.91542L185.236 14.1451C184.686 15.0953 183.316 15.0953 182.766 14.1451L178.015 5.91542C177.467 4.96542 178.152 3.77753 179.25 3.77753H188.752C189.85 3.77753 190.535 4.96542 189.987 5.91542ZM190.106 2.28049C186.33 0.099211 181.674 0.099211 177.896 2.28049C176.761 2.93552 176.062 4.14602 176.062 5.4563C176.062 9.81886 178.389 13.8501 182.168 16.0314C183.302 16.6867 184.699 16.6867 185.834 16.0314C189.613 13.8501 191.939 9.81886 191.939 5.4563C191.939 4.14602 191.241 2.93552 190.106 2.28049Z"
        fill="#3A9AD5"
      />
      <path
        d="M5.02055 38.7288C4.52877 38.9431 3.70868 39.0692 3.12832 39.0692C1.16051 39.0692 0 38.2872 0 35.6632C0 33.052 1.17307 32.3203 3.12832 32.3203C3.70868 32.3203 4.54133 32.4466 5.02055 32.6231L4.70526 33.6829C4.27649 33.4936 3.70868 33.3927 3.179 33.3927C1.87946 33.3927 1.26142 33.708 1.26142 35.6886C1.26142 37.568 1.87946 37.997 3.179 37.997C3.73402 37.997 4.27649 37.8584 4.70526 37.6817L5.02055 38.7288Z"
        fill="white"
      />
      <path d="M8.83098 38.892H7.63235V33.5559H5.27344V32.4961H11.1773V33.5559H8.83098V38.892Z" fill="white" />
      <path
        d="M15.9213 35.8904C15.5177 35.7895 14.9752 35.7516 14.4328 35.7516C13.3478 35.7516 12.9821 35.8651 12.9821 36.8619C12.9821 37.934 13.2723 38.0982 14.4202 38.0982C14.9499 38.0982 15.4923 37.997 15.9213 37.8457V35.8904ZM17.0818 38.5646C16.325 38.8801 15.2022 39.0692 14.3444 39.0692C12.4143 39.0692 11.6953 38.59 11.6953 36.9249C11.6953 35.1082 12.7549 34.818 14.2184 34.818C14.8238 34.818 15.3159 34.8813 15.9213 35.0073C15.9213 33.6701 15.5807 33.3801 14.1805 33.3801C13.5245 33.3801 12.8054 33.4683 12.2882 33.6701L12.036 32.6358C12.6035 32.4845 13.4741 32.3203 14.3697 32.3203C16.2494 32.3203 17.0818 32.8756 17.0818 34.8055V38.5646Z"
        fill="white"
      />
      <path
        d="M21.2315 38.0362C22.5055 38.0362 22.9344 37.7207 22.9344 35.8161C22.9344 34.0752 22.518 33.6462 21.3828 33.6462C20.0203 33.6462 19.7176 34.1508 19.7176 35.8284C19.7176 37.4558 20.2221 38.0362 21.2315 38.0362ZM21.5593 32.5864C23.2751 32.5864 24.1959 33.3056 24.1959 35.8284C24.1959 38.3389 23.2497 39.0704 21.2061 39.0704C19.4274 39.0704 18.4687 38.238 18.4687 35.2736C18.4687 31.1232 19.339 30.4293 21.4963 30.2403C22.3415 30.1645 23.1363 30.051 23.8301 29.748V30.833C23.2498 31.0981 22.4803 31.2241 21.5342 31.3124C20.0835 31.4387 19.8689 32.0314 19.6924 33.4065C20.0456 32.9019 20.6386 32.5864 21.5593 32.5864Z"
        fill="white"
      />
      <path
        d="M29.7963 34.4262C28.9132 35.9778 27.8031 37.555 26.7687 38.892H25.5703V32.4961H26.7687V37.1386C27.8031 35.8014 28.8627 34.1112 29.7963 32.4961H30.9819V38.892H29.7963V34.4262Z"
        fill="white"
      />
      <path
        d="M34.4326 33.5559C34.2812 35.284 33.8522 37.3404 33.3602 38.892H32.086C32.6917 37.0881 33.1837 34.8678 33.2972 32.4961H37.6367V38.892H36.4383V33.5559H34.4326Z"
        fill="white"
      />
      <path
        d="M43.4623 36.8863C43.4623 36.1545 43.1721 35.9023 42.5667 35.9023H40.4093V37.9333H42.5792C43.1972 37.9333 43.4623 37.6557 43.4623 36.8863ZM42.7685 34.9308C44.1182 34.9308 44.7365 35.5744 44.7365 36.8735C44.7365 38.3116 44.1182 38.892 42.7685 38.892H39.2109V32.4961H40.4093V34.9308H42.7685Z"
        fill="white"
      />
      <path
        d="M47.253 35.9778V38.892H46.0546V32.4961H47.253V34.9561H50.23V32.4961H51.4284V38.892H50.23V35.9778H47.253Z"
        fill="white"
      />
      <path
        d="M56.9838 35.8904C56.5802 35.7895 56.0377 35.7516 55.4953 35.7516C54.4103 35.7516 54.0446 35.8651 54.0446 36.8619C54.0446 37.934 54.3348 38.0982 55.4827 38.0982C56.0124 38.0982 56.5548 37.997 56.9838 37.8457V35.8904ZM58.1443 38.5646C57.3875 38.8801 56.2647 39.0692 55.4069 39.0692C53.477 39.0692 52.7578 38.59 52.7578 36.9249C52.7578 35.1082 53.8174 34.818 55.2809 34.818C55.8863 34.818 56.3783 34.8813 56.9838 35.0073C56.9838 33.6701 56.6432 33.3801 55.243 33.3801C54.587 33.3801 53.8679 33.4683 53.3507 33.6701L53.0985 32.6358C53.666 32.4845 54.5366 32.3203 55.4322 32.3203C57.3117 32.3203 58.1443 32.8756 58.1443 34.8055V38.5646Z"
        fill="white"
      />
      <path
        d="M63.8849 33.5055H61.816C61.1854 33.5055 60.8827 33.7324 60.8827 34.4516C60.8827 35.1203 61.1854 35.4354 61.8539 35.4354H63.8849V33.5055ZM59.5959 34.3632C59.5959 33.3541 60.0626 32.4961 61.6144 32.4961H65.0833V38.892H63.8849V36.4068H62.8128C61.8918 36.4068 61.4123 36.76 60.5295 38.892H59.1797C60.2014 36.5961 60.6683 36.1799 61.4377 36.0662C60.0626 36.0411 59.5959 35.2717 59.5959 34.3632Z"
        fill="white"
      />
      <path
        d="M71.0999 37.2527C71.0999 37.7447 71.7809 38.0224 72.601 38.0224C73.9003 38.0224 74.3291 37.5429 74.3291 35.7011C74.3291 33.7331 73.9003 33.3801 72.601 33.3801C72.0711 33.3801 71.5035 33.4557 71.0999 33.6071V37.2527ZM69.914 32.7998C70.6078 32.5096 71.7558 32.3203 72.601 32.3203C74.6446 32.3203 75.5779 33.0646 75.5779 35.6758C75.5779 38.2872 74.5941 39.0692 72.7396 39.0692C71.9323 39.0692 71.4277 38.8422 71.0999 38.6151V41.3146H69.914V32.7998Z"
        fill="white"
      />
      <path
        d="M80.937 35.8904C80.5333 35.7895 79.9908 35.7516 79.4484 35.7516C78.3634 35.7516 77.9975 35.8651 77.9975 36.8619C77.9975 37.934 78.2876 38.0982 79.4358 38.0982C79.9655 38.0982 80.508 37.997 80.937 37.8457V35.8904ZM82.0975 38.5646C81.3406 38.8801 80.2178 39.0692 79.36 39.0692C77.4299 39.0692 76.7109 38.59 76.7109 36.9249C76.7109 35.1082 77.7705 34.818 79.2338 34.818C79.8395 34.818 80.3315 34.8813 80.937 35.0073C80.937 33.6701 80.5963 33.3801 79.1959 33.3801C78.5399 33.3801 77.821 33.4683 77.3039 33.6701L77.0516 32.6358C77.6192 32.4845 78.4897 32.3203 79.3854 32.3203C81.2648 32.3203 82.0975 32.8756 82.0975 34.8055V38.5646Z"
        fill="white"
      />
      <path
        d="M86.2469 38.0362C87.5211 38.0362 87.9501 37.7207 87.9501 35.8161C87.9501 34.0752 87.5337 33.6462 86.3985 33.6462C85.036 33.6462 84.7332 34.1508 84.7332 35.8284C84.7332 37.4558 85.238 38.0362 86.2469 38.0362ZM86.575 32.5864C88.2908 32.5864 89.2115 33.3056 89.2115 35.8284C89.2115 38.3389 88.2654 39.0704 86.2218 39.0704C84.4433 39.0704 83.4844 38.238 83.4844 35.2736C83.4844 31.1232 84.3549 30.4293 86.512 30.2403C87.3572 30.1645 88.1519 30.051 88.8458 29.748V30.833C88.2654 31.0981 87.496 31.2241 86.5499 31.3124C85.0992 31.4387 84.8846 32.0314 84.7081 33.4065C85.0613 32.9019 85.6542 32.5864 86.575 32.5864Z"
        fill="white"
      />
      <path
        d="M91.6599 35.6886C91.6599 37.6059 92.0763 38.0475 93.3756 38.0475C94.6749 38.0475 95.0911 37.6059 95.0911 35.6886C95.0911 33.6829 94.6749 33.342 93.3756 33.342C92.0763 33.342 91.6599 33.6829 91.6599 35.6886ZM90.3984 35.6632C90.3984 33.052 91.3697 32.3203 93.3756 32.3203C95.3939 32.3203 96.3528 33.052 96.3528 35.6632C96.3528 38.2872 95.4067 39.0692 93.3756 39.0692C91.3571 39.0692 90.3984 38.2872 90.3984 35.6632Z"
        fill="white"
      />
      <path d="M100.472 38.892H99.2732V33.5559H96.9141V32.4961H102.819V33.5559H100.472V38.892Z" fill="white" />
      <path
        d="M107.562 35.8904C107.158 35.7895 106.617 35.7516 106.073 35.7516C104.989 35.7516 104.624 35.8651 104.624 36.8619C104.624 37.934 104.914 38.0982 106.062 38.0982C106.592 38.0982 107.133 37.997 107.562 37.8457V35.8904ZM108.724 38.5646C107.966 38.8801 106.843 39.0692 105.987 39.0692C104.055 39.0692 103.336 38.59 103.336 36.9249C103.336 35.1082 104.395 34.818 105.859 34.818C106.466 34.818 106.957 34.8813 107.562 35.0073C107.562 33.6701 107.222 33.3801 105.822 33.3801C105.167 33.3801 104.448 33.4683 103.93 33.6701L103.676 32.6358C104.245 32.4845 105.114 32.3203 106.012 32.3203C107.891 32.3203 108.724 32.8756 108.724 34.8055V38.5646Z"
        fill="white"
      />
      <path d="M114.693 38.892H113.508V32.4961H118.869V38.892H117.681V33.5559H114.693V38.892Z" fill="white" />
      <path
        d="M121.543 34.9694H124.698C124.635 33.6071 124.269 33.3169 123.235 33.3169C122.075 33.3169 121.646 33.6952 121.543 34.9694ZM125.479 38.7162C124.824 38.9178 123.904 39.0692 123.299 39.0692C121.406 39.0692 120.258 38.2872 120.258 35.6632C120.258 33.1276 121.303 32.3203 123.185 32.3203C125.103 32.3203 125.947 33.0269 125.883 35.9409H121.532C121.543 37.6564 122.137 38.0224 123.436 38.0224C123.89 38.0224 124.735 37.8961 125.253 37.7194L125.479 38.7162Z"
        fill="white"
      />
      <path
        d="M128.336 37.2527C128.336 37.7447 129.016 38.0224 129.836 38.0224C131.135 38.0224 131.564 37.5429 131.564 35.7011C131.564 33.7331 131.135 33.3801 129.836 33.3801C129.306 33.3801 128.74 33.4557 128.336 33.6071V37.2527ZM127.148 32.7998C127.842 32.5096 128.991 32.3203 129.836 32.3203C131.879 32.3203 132.813 33.0646 132.813 35.6758C132.813 38.2872 131.829 39.0692 129.975 39.0692C129.167 39.0692 128.662 38.8422 128.336 38.6151V41.3146H127.148V32.7998Z"
        fill="white"
      />
      <path
        d="M139.036 38.7288C138.545 38.9431 137.726 39.0692 137.146 39.0692C135.178 39.0692 134.016 38.2872 134.016 35.6632C134.016 33.052 135.189 32.3203 137.146 32.3203C137.726 32.3203 138.559 32.4466 139.036 32.6231L138.721 33.6829C138.292 33.4936 137.726 33.3927 137.196 33.3927C135.897 33.3927 135.278 33.708 135.278 35.6886C135.278 37.568 135.897 37.997 137.196 37.997C137.751 37.997 138.292 37.8584 138.721 37.6817L139.036 38.7288Z"
        fill="white"
      />
      <path
        d="M140.924 35.6886C140.924 37.6059 141.342 38.0475 142.641 38.0475C143.94 38.0475 144.356 37.6059 144.356 35.6886C144.356 33.6829 143.94 33.342 142.641 33.342C141.342 33.342 140.924 33.6829 140.924 35.6886ZM139.664 35.6632C139.664 33.052 140.634 32.3203 142.641 32.3203C144.659 32.3203 145.618 33.052 145.618 35.6632C145.618 38.2872 144.671 39.0692 142.641 39.0692C140.623 39.0692 139.664 38.2872 139.664 35.6632Z"
        fill="white"
      />
      <path
        d="M148.199 35.9778V38.892H147V32.4961H148.199V34.9561H151.176V32.4961H152.374V38.892H151.176V35.9778H148.199Z"
        fill="white"
      />
      <path
        d="M157.929 35.8904C157.525 35.7895 156.982 35.7516 156.441 35.7516C155.356 35.7516 154.989 35.8651 154.989 36.8619C154.989 37.934 155.278 38.0982 156.427 38.0982C156.957 38.0982 157.5 37.997 157.929 37.8457V35.8904ZM159.089 38.5646C158.333 38.8801 157.21 39.0692 156.352 39.0692C154.422 39.0692 153.703 38.59 153.703 36.9249C153.703 35.1082 154.763 34.818 156.226 34.818C156.831 34.818 157.322 34.8813 157.929 35.0073C157.929 33.6701 157.589 33.3801 156.187 33.3801C155.532 33.3801 154.813 33.4683 154.294 33.6701L154.043 32.6358C154.612 32.4845 155.482 32.3203 156.377 32.3203C158.256 32.3203 159.089 32.8756 159.089 34.8055V38.5646Z"
        fill="white"
      />
      <path
        d="M162.542 33.5559C162.389 35.284 161.962 37.3404 161.469 38.892H160.195C160.8 37.0881 161.293 34.8678 161.405 32.4961H165.746V38.892H164.547V33.5559H162.542Z"
        fill="white"
      />
      <path
        d="M171.289 35.8904C170.884 35.7895 170.341 35.7516 169.8 35.7516C168.713 35.7516 168.348 35.8651 168.348 36.8619C168.348 37.934 168.638 38.0982 169.786 38.0982C170.316 38.0982 170.859 37.997 171.289 37.8457V35.8904ZM172.448 38.5646C171.69 38.8801 170.569 39.0692 169.711 39.0692C167.779 39.0692 167.062 38.59 167.062 36.9249C167.062 35.1082 168.122 34.818 169.585 34.818C170.19 34.818 170.681 34.8813 171.289 35.0073C171.289 33.6701 170.946 33.3801 169.547 33.3801C168.891 33.3801 168.172 33.4683 167.654 33.6701L167.403 32.6358C167.969 32.4845 168.841 32.3203 169.736 32.3203C171.615 32.3203 172.448 32.8756 172.448 34.8055V38.5646Z"
        fill="white"
      />
      <path
        d="M5.28736 25.3712C3.6732 25.3712 2.42389 24.9826 1.53873 24.2059C0.653338 23.4285 0.210876 22.3458 0.210876 20.9593V5.74219H4.56408V20.1771C4.56408 21.0824 5.08028 21.5344 6.11316 21.5344H7.41225V25.3712H5.28736Z"
        fill="white"
      />
      <path
        d="M15.7871 22.228C17.0761 22.228 18.2195 22.0314 19.2181 21.6371C20.2168 21.2447 20.9471 20.718 21.4094 20.0586V19.0259L15.0638 19.7488C13.7556 19.8865 13.1012 20.3095 13.1012 21.0177C13.1012 21.8246 13.9969 22.228 15.7871 22.228ZM27.1791 21.5342H28.5076V25.3709H25.94C24.0213 25.3709 22.7127 24.7657 22.0145 23.5561C20.4798 25.0022 18.3008 25.7301 15.477 25.7397C13.3423 25.7397 11.6818 25.3367 10.4969 24.5301C9.31104 23.7234 8.71881 22.5922 8.71881 21.136C8.71881 19.9063 9.1615 18.9248 10.0467 18.1919C10.932 17.4588 12.2209 16.9995 13.9129 16.812L21.38 16.0008C21.1341 14.5054 19.7809 13.7622 17.322 13.7725C16.033 13.7725 15.0323 13.9467 14.3186 14.2958C13.6053 14.6453 13.2489 15.1353 13.2489 15.7645V15.8531H8.83706V15.7645C8.83706 13.9741 9.60625 12.5424 11.1464 11.47C12.6859 10.3983 14.7544 9.86133 17.3515 9.86133C20.0079 9.86133 22.0782 10.457 23.5638 11.6476C25.0494 12.8374 25.7926 14.481 25.7926 16.5757V20.3244C25.7926 21.131 26.2544 21.5342 27.1791 21.5342Z"
        fill="white"
      />
      <path
        d="M43.8896 17.9342V17.639C43.8896 16.4484 43.4764 15.5214 42.6499 14.8568C41.8234 14.1929 40.6677 13.8609 39.1818 13.8609C37.1656 13.8609 35.6695 14.4216 34.6962 15.5433V20.0296C35.6798 21.1806 37.1752 21.7509 39.1818 21.7413C40.6773 21.7413 41.8355 21.4093 42.6574 20.7447C43.4784 20.0808 43.8896 19.1436 43.8896 17.9342ZM39.8163 9.8616C42.4531 9.8616 44.546 10.5632 46.0958 11.9648C47.6451 13.3664 48.4197 15.2577 48.4197 17.639V17.9342C48.4197 20.315 47.6451 22.2114 46.0958 23.6226C44.546 25.0344 42.4531 25.7399 39.8163 25.7399C37.6818 25.7399 35.9156 25.1847 34.5186 24.0726L34.4012 25.3712H30.3282V5.74219H34.6962V11.4114C36.1129 10.3787 37.8193 9.8616 39.8163 9.8616Z"
        fill="white"
      />
      <path
        d="M62.618 25.7397C59.7751 25.7397 57.5614 25.2894 55.9774 24.3901C54.3933 23.4899 53.6016 22.2132 53.6016 20.56V20.4125H57.8073V20.56C57.8073 21.1209 58.1911 21.5294 58.9582 21.7849C59.726 22.041 60.965 22.1686 62.6767 22.1686C65.5004 22.1686 66.9123 21.7657 66.9123 20.9591V20.8116C66.9123 20.5061 66.7301 20.2725 66.366 20.11C66.0018 19.9481 65.3482 19.8278 64.4036 19.7488L60.4931 19.3504C58.2596 19.1241 56.629 18.6618 55.6011 17.9629C54.573 17.265 54.0593 16.2664 54.0593 14.967V14.6721C54.0593 13.1579 54.8093 11.9769 56.3093 11.1305C57.81 10.2848 59.9029 9.86133 62.5888 9.86133C65.3728 9.86133 67.5073 10.3068 68.9929 11.1974C70.4788 12.0874 71.2212 13.3689 71.2212 15.041V15.2186H67.0306V15.041C67.0306 14.481 66.6712 14.0725 65.9534 13.8161C65.2349 13.5609 64.0735 13.433 62.4705 13.433C59.7552 13.433 58.3927 13.7869 58.3831 14.4954V14.6721C58.3831 15.233 59.0961 15.5874 60.5224 15.7351L64.4922 16.1189C66.8728 16.3648 68.6016 16.8396 69.6794 17.5424C70.7566 18.246 71.295 19.2616 71.295 20.5901V20.8997C71.295 22.4344 70.5278 23.6248 68.9929 24.4705C67.4582 25.3168 65.3331 25.7397 62.618 25.7397Z"
        fill="white"
      />
      <path
        d="M82.4377 13.5211C79.998 13.5211 78.5272 14.328 78.0251 15.9412H86.7614C86.2594 14.328 84.8181 13.5211 82.4377 13.5211ZM91.3804 17.5205V19.0849H77.9076C78.045 20.0294 78.5073 20.7547 79.2943 21.2616C80.0811 21.7684 81.1393 22.0212 82.4669 22.0212C83.6774 22.0212 84.6658 21.8593 85.4336 21.5342C86.2007 21.2097 86.6432 20.767 86.7614 20.2063H91.351C91.1243 21.9769 90.2416 23.3422 88.7021 24.3011C87.1624 25.2602 85.0838 25.7397 82.4669 25.7397C79.663 25.7397 77.4521 25.0588 75.8338 23.696C74.2158 22.3339 73.4062 20.4721 73.4062 18.1106V17.5499C73.4062 15.1693 74.2185 13.2924 75.8414 11.92C77.4644 10.5479 79.6733 9.86133 82.4669 9.86133C85.2909 9.86133 87.482 10.5328 89.0416 11.8757C90.6003 13.2186 91.3804 15.1004 91.3804 17.5205Z"
        fill="white"
      />
      <path
        d="M102.981 10.2305H104.639V14.0964H102.406C100.509 14.0964 99.1752 14.5686 98.4081 15.5131V25.3704H94.0547V10.2305H98.141L98.2597 11.617C99.5382 10.6928 101.114 10.2305 102.981 10.2305Z"
        fill="white"
      />
      <path
        d="M121.489 10.2305H126.094V10.8649L118.98 25.3704H113.3L106.172 10.8649V10.2305H110.761L116.147 21.4156L121.489 10.2305Z"
        fill="white"
      />
      <path d="M132.557 10.2305H128.203V25.3704H132.557V10.2305Z" fill="white" />
      <path
        d="M144.353 25.7397C141.531 25.7397 139.305 25.054 137.677 23.6817C136.047 22.3088 135.234 20.4323 135.234 18.052V17.668C135.234 15.2677 136.052 13.3661 137.684 11.9645C139.317 10.5629 141.54 9.86133 144.353 9.86133C146.972 9.86133 149.111 10.491 150.773 11.7508C152.435 13.0102 153.266 14.6285 153.266 16.6059V16.7234H148.766V16.6059C148.766 15.7992 148.358 15.1399 147.54 14.6285C146.725 14.1168 145.661 13.8606 144.353 13.8606C142.858 13.8606 141.718 14.1878 140.938 14.8424C140.154 15.4967 139.764 16.4481 139.764 17.6974V18.0218C139.764 19.2321 140.159 20.1543 140.944 20.7887C141.732 21.4234 142.869 21.741 144.353 21.741C145.682 21.741 146.75 21.4951 147.556 21.0027C148.362 20.5111 148.766 19.8517 148.766 19.0259V18.9077H153.266V19.0259C153.266 21.0623 152.456 22.6906 150.833 23.9097C149.209 25.1298 147.049 25.7397 144.353 25.7397Z"
        fill="white"
      />
      <path
        d="M163.907 13.5211C161.466 13.5211 159.996 14.328 159.494 15.9412H168.231C167.729 14.328 166.286 13.5211 163.907 13.5211ZM172.85 17.5205V19.0849H159.377C159.514 20.0294 159.975 20.7547 160.763 21.2616C161.551 21.7684 162.608 22.0212 163.937 22.0212C165.147 22.0212 166.135 21.8593 166.902 21.5342C167.67 21.2097 168.112 20.767 168.231 20.2063H172.82C172.592 21.9769 171.711 23.3422 170.17 24.3011C168.631 25.2602 166.553 25.7397 163.937 25.7397C161.131 25.7397 158.921 25.0588 157.302 23.696C155.686 22.3339 154.875 20.4721 154.875 18.1106V17.5499C154.875 15.1693 155.688 13.2924 157.311 11.92C158.932 10.5479 161.142 9.86133 163.937 9.86133C166.759 9.86133 168.95 10.5328 170.51 11.8757C172.069 13.2186 172.85 15.1004 172.85 17.5205Z"
        fill="white"
      />
    </svg>
  </a>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.ui-logo {
  path {
    transition: 1s;
    &:not(.symbol) {
      fill: #fff;
    }
  }
}
</style>
