<template>
  <div class="ui-counter">
    <div class="current">{{ format(current) }}</div>
    <div class="length">
      / <span>{{ format(length) }}</span>
    </div>
    <div v-if="!noArrows" class="arrows">
      <icon-line-left @click.native="$emit('prev')"></icon-line-left>
      <icon-line-right @click.native="$emit('next')"></icon-line-right>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    length: {
      type: Number,
      default() {
        return 0
      }
    },
    current: {
      type: Number,
      default() {
        return 0
      }
    },
    noArrows: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  methods: {
    format(value) {
      return value < 10 ? `0${value}` : `${value}`
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-counter {
  display: inline-flex;
  position: relative;
}
.current {
  font-size: 6.25em;
  opacity: 0.2;
  font-weight: 800;
  width: 1.2em;
}
.length {
  font-size: 1.125em;
  margin-top: 1.3em;
  span {
    margin-left: 0.5em;
  }
}
.arrows {
  display: flex;
  align-items: center;
  align-self: flex-end;
  position: relative;
  top: -1em;
  & > * {
    height: 1.5em;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 0.5em;
    }
  }
}
@media screen and (max-width: 767px) {
  .ui-counter {
    flex-direction: column;
  }
  .current {
    font-size: 4em;
    margin-bottom: -0.5em;
  }
  .arrows {
    top: auto;
    margin-top: 0.3em;
  }
}
</style>
