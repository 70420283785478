<template>
  <transition leave-active-class="animated slideOutDown" enter-active-class="animated slideInUp">
    <div v-if="opened" class="layout-modal-popup abs">
      <div class="overlay abs" @click="close"></div>
      <div class="window animated fadeInUp" style="animation-delay: .5s">
        <div class="close-button" @click="close"><img src="@/assets/icons/close.svg" alt="" /></div>
        <div class="scroll-area">
          <form v-if="!sended" class="before" @submit.prevent="submit">
            <slot></slot>
          </form>
          <div v-else class="after">
            <svg
              style="animation-delay: .2s"
              class="animated fadeInUp"
              width="512"
              height="512"
              viewBox="0 0 512 512"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 256C0 397.167 114.854 512 256 512C397.168 512 512 397.167 512 256C512 114.853 397.168 0 256 0C114.854 0 0 114.853 0 256ZM39.6582 256C39.6582 136.705 136.705 39.659 256 39.659C375.295 39.659 472.342 136.705 472.342 256C472.342 375.275 375.295 472.341 256 472.341C136.705 472.341 39.6582 375.295 39.6582 256ZM210.926 344.262C214.674 348.069 219.752 350.191 225.066 350.191C225.146 350.191 225.221 350.191 225.295 350.195C225.35 350.197 225.404 350.202 225.463 350.21C230.916 350.091 236.072 347.751 239.74 343.706L374.779 194.985C382.135 186.855 381.541 174.323 373.432 166.966C365.361 159.629 352.828 160.204 345.432 168.314L224.49 301.509L166.033 242.1C158.359 234.287 145.807 234.208 137.994 241.862C130.182 249.556 130.082 262.088 137.756 269.901L210.926 344.262Z"
                fill="var(--dark)"
              />
            </svg>
            <div class="answer animated fadeInUp" style="animation-delay: .5s" v-html="answer"></div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default() {
        return ''
      }
    },
    action: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      sended: false,
      answer: ''
    }
  },
  computed: {
    opened() {
      return this.$route.hash === `#${this.id}`
    }
  },
  methods: {
    close() {
      this.$router.push({ hash: null })
    },
    async submit($event) {
      const form = $event.target
      const formData = new FormData(form)
      //
      if (this.action && process.env.NODE_ENV !== 'development') {
        const answer = (await this.$axios.post(this.action, formData)).data
        this.answer = answer
        this.sended = true
      } else {
        // Пример ответа
        this.answer = `
        <div class="medium-title blue-text">Успешно</div>
        <div class="small-paragraph">Мы свяжемся с Вами в ближайшее время</div>
        `
        this.sended = true
      }
    }
  }
}
</script>

<style lang="scss">
.layout-modal-popup {
  position: fixed !important;
  z-index: 999;
  height: 100vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
  .overlay {
    background: rgba(0, 0, 0, 0.8);
  }
  .window {
    position: relative;

    width: 500px;
    max-width: calc(100% - 30px);
    background: #fff;
  }
  .scroll-area {
    overflow: scroll;
    max-height: calc(100vh - 10em);
    padding: 3em;
  }
  .ui-input {
    &:not(.ui-input + .ui-input) {
      margin-top: 2em;
    }
  }
  .ui-button {
    margin-top: 2em;
  }
  .after {
    svg {
      width: 5em;
      margin-bottom: 2.2em;
      height: 5em;
    }
  }
  .close-button {
    position: absolute;
    left: 100%;
    width: 5em;
    height: 5em;
    top: 0;
    background: var(--dark);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      img {
        transform: rotate(90deg);
      }
    }
    img {
      width: 40%;
      height: 40%;
      transition: 0.5s;
    }
  }
}
@media screen and (max-width: 767px) {
  .layout-modal-popup {
    .close-button {
      left: auto;
      right: 0;
    }
  }
}
</style>
