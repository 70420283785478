<template>
  <div class="section-services">
    <layout-lines fill></layout-lines>
    <div class="container wide">
      <div class="row">
        <div class="col-12 text-left">
          <div class="big-title blue-text">
            <slot name="title"></slot>
          </div>
          <!--  -->
          <div class="services">
            <slot name="services"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.section-services {
  position: relative;
  padding-bottom: 4em;
}
.services {
  display: grid;
  grid-template-columns: 1.6fr 1fr;
  margin-top: 4em;
  grid-gap: 0.75em;
  & > * {
    position: relative;
    color: #fff;
    padding: 2em;
    box-shadow: 0px 34px 54px rgba(0, 0, 0, 0.25);
    min-height: 18em;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    &:hover {
      img {
        transform: scale(1.2);
      }
    }
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 48.21%, rgba(0, 0, 0, 0.5) 100%);
      z-index: 0;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 1s;
    }
    span {
      &:nth-of-type(1) {
        position: relative;
        font-size: 2.5em;
        font-weight: 800;
        z-index: 2;
        text-decoration: underline;
      }
      &:nth-of-type(2) {
        position: relative;
        font-size: 1em;
        z-index: 2;
        margin-top: 0.5em;
      }
    }
    &:nth-child(2) {
      grid-row: span 2;
    }
  }
}
@media screen and (max-width: 767px) {
  .services {
    display: block;
    & > * {
      margin-bottom: 1em;
    }
  }
}
</style>
