<template>
  <div class="ui-finder">
    <icon-zoom class="search"></icon-zoom>
    <input v-model="query" autofocus type="text" placeholder="Начните искать..." @input="fetch" />
    <icon-close class="close" @click.native="$emit('close')"></icon-close>
    <!--  -->
    <transition enter-active-class="animated fadeInDown">
      <div v-if="resultsOpened" class="results-wrapper">
        <div class="results-info">
          <span v-if="query">Найдено {{ results.length }} результатов по запросу: {{ query }}</span>
          <span v-else>Введите текст для поиска</span>
        </div>
        <div class="results-scroll-area">
          <vue-scroll
            :ops="{
              rail: {
                background: '#fff'
              },
              bar: {
                background: '#fff'
              }
            }"
          >
            <div class="results">
              <a v-for="(result, index) in results" :key="index" :href="result.link" class="result">
                <div class="result-title">{{ result.title }}</div>
                <div class="result-type">{{ result.type }}</div>
              </a>
            </div>
          </vue-scroll>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from 'axios'
import vueScroll from 'vuescroll'
export default {
  components: {
    vueScroll
  },
  data() {
    return {
      query: '',
      resultsOpened: false,
      results: []
    }
  },
  methods: {
    async fetch() {
      this.resultsOpened = true
      this.results = []
      try {
        const results = (await axios.get(`/api/search?query=${this.query}`)).data
        this.results = results
      } catch (error) {
        console.error(error)
        // Наполняю фейковыми данными для демонстрации. В проде удалить
        this.query
          ? (this.results = [
              {
                title: 'Средний менеджмент',
                type: 'Услуги',
                link: '/services/service-1'
              },
              {
                title: 'Средний менеджмент',
                type: 'Услуги',
                link: '/services/service-1'
              },
              {
                title: 'Средний менеджмент',
                type: 'Услуги',
                link: '/services/service-1'
              },
              {
                title: 'Средний менеджмент',
                type: 'Услуги',
                link: '/services/service-1'
              },
              {
                title: 'Средний менеджмент',
                type: 'Услуги',
                link: '/services/service-1'
              },
              {
                title: 'Средний менеджмент',
                type: 'Услуги',
                link: '/services/service-1'
              },
              {
                title: 'Средний менеджмент',
                type: 'Услуги',
                link: '/services/service-1'
              },
              {
                title: 'Средний менеджмент',
                type: 'Услуги',
                link: '/services/service-1'
              },
              {
                title: 'Средний менеджмент',
                type: 'Услуги',
                link: '/services/service-1'
              },
              {
                title: 'Средний менеджмент',
                type: 'Услуги',
                link: '/services/service-1'
              },
              {
                title: 'Средний менеджмент',
                type: 'Услуги',
                link: '/services/service-1'
              },
              {
                title: 'Средний менеджмент',
                type: 'Услуги',
                link: '/services/service-1'
              },
              {
                title: 'Средний менеджмент',
                type: 'Услуги',
                link: '/services/service-1'
              },
              {
                title: 'Средний менеджмент',
                type: 'Услуги',
                link: '/services/service-1'
              },
              {
                title: 'Средний менеджмент',
                type: 'Услуги',
                link: '/services/service-1'
              },
              {
                title: 'Средний менеджмент',
                type: 'Услуги',
                link: '/services/service-1'
              },
              {
                title: 'Средний менеджмент',
                type: 'Услуги',
                link: '/services/service-1'
              },
              {
                title: 'Средний менеджмент',
                type: 'Услуги',
                link: '/services/service-1'
              },
              {
                title: 'Средний менеджмент',
                type: 'Услуги',
                link: '/services/service-1'
              },
              {
                title: 'Средний менеджмент',
                type: 'Услуги',
                link: '/services/service-1'
              },
              {
                title: 'Средний менеджмент',
                type: 'Услуги',
                link: '/services/service-1'
              },
              {
                title: 'Средний менеджмент',
                type: 'Услуги',
                link: '/services/service-1'
              },
              {
                title: 'Средний менеджмент',
                type: 'Услуги',
                link: '/services/service-1'
              },
              {
                title: 'Средний менеджмент',
                type: 'Услуги',
                link: '/services/service-1'
              }
            ])
          : (this.results = [])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-finder {
  position: absolute;
  width: 100%;
  height: calc(100% + 1px);
  background: var(--dark);
  left: 0;
  top: 0;
  color: #fff;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  padding: 0 var(--padd);
  gap: var(--gap);
  --padd: 3em;
  --gap: 2.5em;
  --icon-width: 1.25em;
}
.search {
  --dark: #fff;
}
.search,
.close {
  width: var(--icon-width);
  height: auto;
  align-self: center;
}
.close {
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    transform: rotate(90deg);
  }
}
input {
  font-size: 1.5em;
  font-weight: 500;
  color: #fff;
  background: none;
  border: none;
  font-family: inherit;
  &::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  &::-moz-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  &:-moz-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  &:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}
.results-wrapper {
  position: absolute;
  left: 0;
  top: var(--header-height);
  width: 100%;
  background: var(--dark);
  height: calc(100vh - var(--header-height));
}
.results-info {
  border-top: solid 1px rgba(255, 255, 255, 0.2);
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  padding: 1em calc(var(--padd) + var(--icon-width) + var(--gap));
  span {
    font-size: 0.75em;
  }
}
.results-scroll-area {
  height: 100%;
}
.result {
  padding: 1em calc(var(--padd) + var(--icon-width) + var(--gap));
  display: block;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  transition: 0.3s;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
    border-bottom-color: transparent;
  }
  &-title {
    font-size: 1.25em;
    font-weight: 600;
  }
  &-type {
    font-size: 0.875em;
    opacity: 0.6;
    margin-top: 0.3em;
  }
}
@media screen and (max-width: 767px) {
  .ui-finder {
    --padd: 15px;
    --gap: 15px;
  }
}
</style>
