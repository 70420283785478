<template>
  <div class="section-rich-text">
    <layout-lines fill></layout-lines>
    <div class="prefix">
      <div class="container">
        <div class="row">
          <div class="col-12 text-left">
            <slot name="prefix"></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="title-wrapper">
      <div class="l-container">
        <div class="l-col"></div>
        <div class="l-col l-3">
          <div :class="[middleTitle ? 'middle-title' : 'big-title blue-text']">
            <slot name="title"></slot>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$slots.columns" class="l-row">
      <div class="l-container">
        <div class="l-col"></div>
        <div class="l-col l-4">
          <div class="content">
            <div v-if="$slots.columns" class="columns rich-text">
              <slot name="columns"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$slots.text" class="container">
      <div class="row">
        <div class="col-12 text-left">
          <div class="rich-text">
            <slot name="text"></slot>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$slots.button" class="container">
      <div class="row">
        <div class="col-12 text-left">
          <div class="button-wrapper">
            <slot name="button"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    middleTitle: {
      type: Boolean,
      default() {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.section-rich-text {
  position: relative;
  padding-bottom: 4em;
}
.columns {
  display: grid;
  grid-auto-columns: calc(var(--sidebar-width) * 2);
  grid-auto-flow: column;
  & > * {
    padding-right: var(--header-height);
  }
}
.title-wrapper {
  margin-bottom: 3em;
}
.button-wrapper {
  margin-top: 2em;
}
@media screen and (max-width: 767px) {
  .columns {
    display: block;
    & > * {
      padding-right: 0 !important;
    }
  }
}
</style>
