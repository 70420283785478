<template>
  <div class="layout-tariff">
    <div class="small-title blue-text">
      <slot name="name"></slot>
    </div>
    <div class="caption">
      <slot name="desc"></slot>
    </div>
    <div class="features">
      <slot name="features"></slot>
    </div>
    <div class="button-wrapper">
      <slot name="button"></slot>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.layout-tariff {
  box-shadow: 0px 4px 21px #e8efff;
  padding: 2em;
}
.features {
  &:before,
  &:after {
    content: '';
    width: 4.4em;
    height: 4px;
    background: var(--bg);
    position: relative;
    border-radius: 10px;
    display: block;
    margin: 1.5em 0;
  }
  & > * {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 1em;
    }
    img {
      margin-right: 1em;
      width: 2.5em;
      height: 2.5em;
    }
    div {
      & > * {
        &:nth-child(1) {
          font-weight: 800;
          font-size: 1.75em;
        }
        &:nth-child(2) {
          font-size: 0.875em;
        }
      }
    }
  }
}
</style>
