<template>
  <div class="section-contacts">
    <div class="maps abs">
      <div v-for="(item, index) in tabs" v-show="index === current" :key="index" class="map abs">
        <iframe v-if="index === current" class="abs" :src="item.map" frameborder="0"></iframe>
        <div class="overlay abs"></div>
      </div>
    </div>
    <layout-lines fill></layout-lines>
    <div class="container">
      <div class="row">
        <div class="col-6 text-left">
          <div class="big-title blue-text">
            <slot name="title"></slot>
          </div>
          <!--  -->
          <div class="tabs">
            <div
              v-for="(city, index) in tabs"
              :key="index"
              :class="[{ active: index === current }]"
              class="tab"
              @click="
                current = index
                toggle()
              "
            >
              <div class="small-title blue-text">{{ city.name }}</div>
            </div>
          </div>
          <!--  -->
          <div class="addresses">
            <slot name="addresses"></slot>
          </div>
          <!--  -->
          <div class="button-wrapper">
            <slot name="button"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: 0,
      tabs: []
    }
  },
  mounted() {
    this.tabs = this.$slots.addresses
      .filter(item => item.tag)
      .map(item => {
        return { name: item.data.attrs['data-city'], map: item.data.attrs['iframe-src'] }
      })
    this.toggle()
  },
  methods: {
    toggle() {
      const contents = this.$el.querySelectorAll('.addresses > *')
      contents.forEach((content, index) => {
        index !== this.current ? content.classList.remove('active') : content.classList.add('active')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.section-contacts {
  padding-bottom: 4em;
  position: relative;
}
.maps {
  filter: grayscale(90%);
}
.tabs {
  display: flex;
  align-items: center;
  margin-top: 3em;
  margin-bottom: 2em;
}
.tab {
  margin-right: 1em;
  opacity: 0.3;
  transition: 0.5s;
  cursor: pointer;
  pointer-events: all;
  &.active {
    opacity: 1;
  }
}
.overlay {
  pointer-events: none;
  background: linear-gradient(90deg, #ffffff 36.19%, rgba(255, 255, 255, 0) 59.44%),
    linear-gradient(360deg, #ffffff 0%, rgba(255, 255, 255, 0) 19.25%),
    linear-gradient(180deg, #ffffff 8.51%, rgba(255, 255, 255, 0) 38.72%);
}
.container {
  pointer-events: none;
}
.button-wrapper {
  margin-top: 3em;
  pointer-events: all;
}
.addresses {
  & > * {
    display: none;
    &.active {
      display: block;
    }
    & > * {
      pointer-events: all;
      // строка
      margin-bottom: 2em;
      & > * {
        // Позиция в строке
        display: block;
        &:nth-child(1) {
          font-size: 0.875em;
          margin-bottom: 0.8em;
          color: var(--subcolor);
        }
        &:nth-child(2) {
          font-size: 1.125em;
          line-height: 190%;
          font-weight: 800;
        }
        &:nth-child(3) {
          font-size: 0.875em;
          color: var(--subcolor);
        }
      }
    }
  }
}
</style>
