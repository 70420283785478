<template>
  <div :class="[!noCol ? 'col-6 col-xs-12' : '']" class="layout-project-card text-left">
    <div class="card">
      <div class="image">
        <slot name="image"></slot>
        <div class="color-overlay abs"></div>
        <div class="dark-overlay abs"></div>
      </div>
      <div class="content">
        <div class="name">
          <slot name="name"></slot>
        </div>
        <div class="desc">
          <slot name="desc"></slot>
        </div>
      </div>
      <div class="feats">
        <slot name="feats"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    noCol: {
      type: Boolean,
      default() {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-project-card {
}
.card {
  min-height: 26em;
  position: relative;
  width: 90%;
  box-shadow: 0px 34px 54px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  padding: 3em;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 2em;
  color: #fff;
  &:hover {
    img {
      transform: scale(1.3);
    }
  }
}
.content {
  position: relative;
}
.feats {
  position: relative;
  display: flex;
  margin-top: auto;
  & > * {
    &:not(:last-child) {
      margin-right: 2.5em;
    }
    & > div:nth-child(1) {
      font-size: 1.875em;
      font-weight: 900;
    }
  }
}
.name {
  font-size: 2.5em;
  font-weight: 900;
  text-decoration: underline;
}
.desc {
  margin-top: 0.5em;
}
.image {
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 2s;
  }
  .color-overlay {
    background: linear-gradient(180deg, rgba(23, 93, 171, 0.4) 0%, rgba(23, 93, 171, 0.4) 100%);
    mix-blend-mode: darken;
  }
  .dark-overlay {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 62.38%, rgba(0, 0, 0, 0.51) 100%),
      linear-gradient(180deg, rgba(0, 0, 0, 0) 62.62%, rgba(0, 0, 0, 0.51) 100%);
  }
}
@media screen and (max-width: 767px) {
  .card {
    width: 100%;
    padding: 1.5em;
  }
  .feats {
    & > * {
      &:not(:last-child) {
        margin-right: 0.5em;
      }
    }
  }
}
</style>
