<template>
  <svg
    class="icon-arrow-bottom"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38 20C38 29.9411 29.9411 38 20 38C10.0589 38 2 29.9411 2 20C2 10.0589 10.0589 2 20 2C29.9411 2 38 10.0589 38 20ZM40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM20.5747 25.253L25.7471 20.0805C26.0645 19.7631 26.0645 19.2485 25.7471 18.9311C25.4297 18.6137 24.9151 18.6137 24.5977 18.9311L20.8128 22.716V14.7826H19.1872V22.716L15.4023 18.9311C15.0849 18.6137 14.5703 18.6137 14.2529 18.9311C13.9355 19.2485 13.9355 19.7631 14.2529 20.0805L19.4253 25.253C19.7427 25.5704 20.2573 25.5704 20.5747 25.253Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.icon-arrow-bottom {
  cursor: pointer;
  path {
    transition: 1s;
  }
}
</style>
