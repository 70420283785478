<template>
  <div class="section-faq">
    <layout-lines fill></layout-lines>
    <div class="container">
      <div class="row">
        <div class="col-12 text-left">
          <div class="big-title blue-text">
            <slot name="title"></slot>
          </div>
          <div class="faq">
            <slot name="faq"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      opened: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.section-faq {
  position: relative;
  padding-bottom: 4em;
}
.faq {
  margin-top: 3em;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.25em;
  align-items: flex-start;
}
@media screen and (max-width: 767px) {
  .faq {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
