<template>
  <div data-theme="light" class="full-page-section section-personal">
    <layout-lines fill></layout-lines>
    <div class="section-content">
      <div class="container wide">
        <div class="row">
          <div class="col-12 text-left">
            <div class="header">
              <div class="big-title blue-text">
                <slot name="title"></slot>
              </div>
              <ui-counter
                :current="current + 1"
                :length="length"
                @next="$refs.slider.next()"
                @prev="$refs.slider.prev()"
              ></ui-counter>
            </div>
          </div>
        </div>
      </div>
      <div class="container wide">
        <div class="row">
          <div class="col-12 no-padd">
            <div class="cards">
              <layout-own-carousel
                ref="slider"
                :xs-count="1"
                :count="2"
                gap="2vw"
                @update-current="current = $event"
                @update-length="length = $event"
              >
                <slot name="personal"></slot>
              </layout-own-carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      length: 0,
      current: 0
    }
  }
  // mounted() {
  //   const length = this.$el.querySelectorAll('.slides > *').length
  //   this.length = length
  // }
}
</script>

<style lang="scss" scoped>
.section-personal {
  position: relative;
  overflow: hidden;
}
.fullpage {
  .section-content {
    min-height: 100vh;
    padding-top: var(--header-height);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .header {
    margin-bottom: 0;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3em;
}
</style>
