<template>
  <div class="section-digits-carousel">
    <layout-lines fill></layout-lines>
    <div class="section-bg"></div>
    <div class="container wide">
      <div class="row">
        <div class="col-8 text-left">
          <div class="middle-title blue-text">
            <slot name="title"></slot>
          </div>
          <div class="paragraph">
            <slot name="subtitle"></slot>
          </div>
        </div>
        <div class="col-4 text-right">
          <ui-counter :current="current + 1" :length="length" @prev="prev" @next="next"></ui-counter>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="carousel-wrapper">
      <div class="carousel">
        <slot name="digits"></slot>
      </div>
    </div>
    <!--  -->
    <div class="progress-wrapper">
      <div class="container wide">
        <div class="row">
          <div class="col-12">
            <div class="progress">
              <div
                class="progress-inner"
                :style="{
                  width: `${((current + 1) / length) * 100}%`
                }"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: 0,
      items: []
    }
  },
  computed: {
    length() {
      return this.items.length
    }
  },
  mounted() {
    const items = [...this.$el.querySelectorAll('.carousel > *')]
    this.items = items
  },
  methods: {
    animate(width) {
      const carousel = this.$el.querySelector('.carousel')
      carousel.style.transform = `translate3d(calc((${width}px + var(--gap) * ${this.current}) * -1),0,0)`
    },
    getWidth(max) {
      const itemsForSumm = this.items.slice(0, max)
      let width = 0
      for (const item of itemsForSumm) {
        width = width + item.offsetWidth
      }
      return width
    },
    prev() {
      const width = this.getWidth(this.current - 1)
      if (this.current !== 0) {
        this.current--
        this.animate(width)
      }
    },
    next() {
      const width = this.getWidth(this.current + 1)
      if (this.current !== this.length - 1) {
        this.current++
        this.animate(width)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.section-digits-carousel {
  position: relative;
  padding: 3em 0 7em 0;
  overflow: hidden;
  --gap: 7em;
}
.section-bg {
  height: calc(100% - 4em);
}
.paragraph {
  color: var(--subcolor);
  margin-top: 2em;
}
.carousel-wrapper {
  position: relative;
  padding-left: var(--sidebar-width);
  margin-top: 3em;
}
.carousel {
  display: grid;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  grid-gap: var(--gap);
  transition: 0.5s;
  & > div {
    color: var(--dark);
    transition: 0.5s;
    & > * {
      &:nth-child(1) {
        white-space: nowrap;
        font-size: 7.35em;
        font-weight: 800;
        line-height: 91%;
      }
      &:nth-child(2) {
        margin-top: 1em;
        font-size: 0.875em;
      }
    }
  }
}
.progress {
  width: 100%;
  height: 8px;
  border-radius: 10px;
  background: #e1e7f4;
  position: relative;
  overflow: hidden;
}
.progress-inner {
  position: absolute;
  height: 100%;
  border-radius: inherit;
  background: var(--dark);
  left: 0;
  top: 0;
  transition: 1s;
}
.progress-wrapper {
  margin-top: 3em;
}
@media screen and (max-width: 767px) {
  .carousel-wrapper {
    padding-left: 15px;
  }
  .carousel {
    & > div {
      & > * {
        &:nth-child(1) {
          font-size: 5em;
        }
      }
    }
  }
}
</style>
