<template>
  <div class="section-steps">
    <layout-lines fill></layout-lines>
    <div class="container wide">
      <div class="row">
        <div class="col-12 text-left">
          <div class="big-title blue-text">
            <slot name="title"></slot>
          </div>
          <!--  -->
          <div class="steps">
            <slot name="steps"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    const stepRows = this.$el.querySelectorAll('.steps > *')
    stepRows.forEach((row, index) => {
      const steps = [...row.children]
      steps.forEach((step, stepIndex) => {
        const div = document.createElement('div')
        div.classList.add('index')
        div.innerHTML = `0${index * 3 + stepIndex + 1}`
        step.appendChild(div)
      })
    })
  }
}
</script>

<style lang="scss" scoped>
.section-steps {
  padding-bottom: 0em;
  position: relative;
}
.steps {
  margin-top: 15em;
  .steps-row {
    display: grid;
    grid-template-columns: repeat(3, 20em);
    justify-content: space-between;
    &:nth-child(2n) {
      // Последняя строка из четных
      &:last-child {
        & > * {
          &:last-child {
            &::before {
              display: none;
            }
          }
        }
      }
      & > * {
        grid-row: 1/2;
        &:nth-child(1) {
          grid-column: -1/-2;
        }
        &:nth-child(2) {
          grid-column: 2/3;
        }
        &:nth-child(3) {
          grid-column: 1/2;
        }
      }
      &:not(:last-child) {
        & > *:last-child {
          &:after {
            content: '';
            width: 11em;
            height: 8em;
            position: absolute;
            right: 6em;
            top: 100%;
            background: url('/src/assets/icons/track3.svg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      }
    }
    &:nth-child(2n + 1) {
      // Последняя строка из нечетных
      &:last-child {
        & > * {
          &:last-child {
            &::after {
              display: none;
            }
          }
        }
      }
      &:not(:last-child) {
        & > * {
          &:last-child {
            &:after {
              content: '';
              width: 11em;
              height: 8em;
              position: absolute;
              right: 6em;
              top: 100%;
              background: url('/src/assets/icons/track3.svg');
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
    & > * {
      flex-basis: 20em;
      margin-bottom: 10em;
      position: relative;
      &:nth-child(3n - 1) {
        top: -8em;
        &::before,
        &:after {
          content: '';
          width: 16vw;
          height: 100%;
          position: absolute;
          top: 8em;
          background-size: contain !important;
          background-position: center !important;
          background-repeat: no-repeat !important;
          // border: solid 1px;
        }
        &::before {
          right: 90%;
          background: url('/src/assets/icons/track.svg');
          z-index: -1;
        }
        &::after {
          left: 70%;
          background: url('/src/assets/icons/track2.svg');
          z-index: -1;
        }
      }
      img {
        width: 8.75em;
        height: 8.75em;
        display: block;
        margin-bottom: 1em;
      }
      .small-title {
        margin-bottom: 0.5em;
        display: block;
      }
      .caption {
        color: var(--subcolor);
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .steps {
    margin-top: 3em;
    .steps-row {
      display: block;
      :before,
      :after {
        display: none !important;
      }
      & > * {
        top: 0 !important;
        margin-bottom: 4em;
      }
    }
  }
}
</style>
<style lang="scss">
.section-steps {
  .index {
    font-size: 13vw;
    color: #e8eaf0;
    font-weight: 800;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
}
@media screen and (max-width: 767px) {
  .section-steps {
    .index {
      font-size: 10em;
    }
  }
}
</style>
