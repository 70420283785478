<template>
  <div class="ui-faq-card">
    <div class="qa">
      <div class="q" @click="opened = !opened">
        <span><slot name="q"></slot></span>
      </div>
      <div :class="[{ opened }]" class="a small-paragraph"><slot name="a"></slot></div>
    </div>
    <div class="button" @click="opened = !opened">
      <div class="cross">
        <div :class="[{ opened }]" class="line"></div>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      opened: false
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-faq-card {
  --size: 3.75em;
  display: flex;
}
.button {
  flex-shrink: 0;
  width: var(--size);
  height: var(--size);
  background: var(--dark);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.qa {
  flex-grow: 1;
  border: solid 3px var(--dark);
  padding: 0 1.5em;
}
.cross {
  --size: 22px;
  width: var(--size);
  height: var(--size);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.line {
  width: 2px;
  height: var(--size);
  background: currentColor;
  transition: 0.5s;
  &:nth-child(1) {
    &.opened {
      height: 0;
    }
  }
  &:nth-child(2) {
    position: absolute;
    transform: rotate(90deg);
  }
}
.q {
  cursor: pointer;
  span {
    font-size: 1.125em;
    font-weight: 600;
  }
  color: var(--dark);
  min-height: calc(var(--size) - 6px);
  display: flex;
  align-items: center;
}
.a {
  color: var(--subcolor);
  max-height: 0;
  overflow: hidden;
  transition: 0.3s;
  &.opened {
    max-height: 30em;
    margin-bottom: 2em;
  }
}
</style>
