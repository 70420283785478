<template>
  <div class="layout-header" :class="[theme]">
    <a href="/" class="sidebar-place">
      <ui-logo></ui-logo>
    </a>
    <div class="phone">
      <slot name="phone"></slot>
    </div>
    <div :class="[{ active: menuOpened }]" class="menu">
      <slot name="menu"></slot>
    </div>
    <ui-search-button class="search" @click.native="finderOpened = true"></ui-search-button>
    <div class="menu-button" @click="menuOpened = !menuOpened">
      <img v-if="!menuOpened" src="@/assets/icons/menu.svg" alt="" />
      <img v-else src="@/assets/icons/close.svg" alt="" />
    </div>
    <transition enter-active-class="animated slideInRight" leave-active-class="animated slideOutRight">
      <ui-finder v-if="finderOpened" @close="finderOpened = false"></ui-finder>
    </transition>
  </div>
</template>

<script>
import uiLogo from '@/components/ui/ui-logo'
import uiSearchButton from '@/components/ui/ui-search-button'
import uiFinder from '@/components/ui/ui-finder.vue'
export default {
  components: {
    uiLogo,
    uiSearchButton,
    uiFinder
  },
  data() {
    return {
      menuOpened: false,
      finderOpened: false
    }
  },
  computed: {
    theme() {
      return this.$store.state.nav.theme
    }
  },
  mounted() {
    const menuItems = [...this.$el.querySelectorAll('.menu a')]
    const activeItem = menuItems.find(item => item.pathname === this.$route.path)
    activeItem ? activeItem.classList.add('active') : ''
  }
}
</script>

<style lang="scss" scoped>
.layout-header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  color: #fff;
  display: flex;
  height: var(--header-height);
  border-bottom: solid 1px var(--line);
  transition: 1s;
}
.sidebar-place {
  width: var(--sidebar-width);
  display: flex;
  align-items: center;
  justify-content: center;
  .ui-logo {
    width: 60%;
  }
}
.menu {
  display: flex;
  a {
    margin-right: 1.8em;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 1.0625em;
    cursor: pointer;
    &:after {
      content: '';
      width: 0%;
      height: 3px;
      transition: 0.5s;
      left: 0;
      bottom: 0;
      background: currentColor;
      position: absolute;
    }
    &.active,
    &:hover {
      &::after {
        width: 100%;
      }
    }
  }
}
.phone {
  margin-right: auto;
  margin-left: 3em;
  align-self: center;
  a {
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      margin-right: 0.5em;
    }
    font-size: 1.0625em;
    font-weight: 600;
  }
}
.menu-button {
  display: none;
}
@media screen and (max-width: 767px) {
  .search {
    // display: none;
  }
  .sidebar-place {
    padding-left: 15px;
    .ui-logo {
      width: 100%;
    }
  }
  .menu {
    position: absolute;
    background: var(--dark);
    width: 100%;
    left: 0;
    top: var(--header-height);
    height: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    overflow: hidden;
    transition: 0.5s;
    visibility: hidden;
    a {
      font-size: 2em;
      margin-right: 0;
      margin: 0.3em 0;
    }
    &.active {
      visibility: visible;
      height: calc(100vh - var(--header-height));
    }
  }
  .menu-button {
    display: flex;
    width: var(--header-height);
    height: var(--header-height);
    background: var(--dark);
    align-items: center;
    justify-content: center;
    img {
      width: 35%;
      height: 35%;
    }
  }
  .phone {
    margin-left: auto;
    svg {
      width: 0.8em;
      height: auto;
    }
  }
}
</style>

<style lang="scss">
.layout-header {
  &.blue {
    background: var(--dark);
  }
  &.light {
    color: var(--color);
    background: #fff;
    .menu {
      a {
        &::after {
          background: var(--dark);
        }
      }
    }
    .ui-logo {
      path:not(.symbol) {
        fill: var(--dark);
      }
    }
    .icon-phone {
      path {
        fill: var(--color);
      }
    }
    .ui-search-button {
      background: var(--dark);
      .icon-zoom {
        path {
          fill: #fff;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .layout-header {
    &.light {
      .ui-search-button {
      }
    }
  }
}
</style>
