<template>
  <div data-theme="blue" class="full-page-section section-projects">
    <layout-lines fill></layout-lines>
    <div class="section-content">
      <div class="container wide">
        <div class="row">
          <div class="col-12 text-left">
            <div class="header">
              <div class="big-title">
                <slot name="title"></slot>
              </div>
              <slot name="button"></slot>
            </div>
          </div>
        </div>
        <div class="row projects flex-wrap">
          <slot name="projects"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.section-projects {
  background: var(--dark);
  position: relative;
  color: #fff;
}
.section-content {
  // min-height: 100vh;
  padding: var(--header-height) 0;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.projects {
  margin-top: 4em;
}
@media screen and (max-width: 767px) {
  .header {
    flex-flow: wrap;
    .ui-button {
      margin-top: 1.5em;
    }
  }
}
</style>
